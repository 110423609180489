import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const CourseNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
      <div className="p-8 bg-white rounded-lg shadow-lg">
        <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500 mx-auto mb-4" />
        <h1 className="text-2xl font-semibold mb-2">Kurs Detay Bulunamadı</h1>
        <p className="text-gray-600 mb-6">
          Üzgünüz, aradığınız kurs detayına ulaşılamıyor. Lütfen doğru bağlantıyı
          kullandığınızdan emin olun veya tekrar deneyin.
        </p>
        <button
          onClick={() => window.history.back()}
          className="px-6 py-2 bg-blue-500 text-white font-medium rounded hover:bg-blue-600"
        >
          Geri Dön
        </button>
      </div>
    </div>
  );
};

export default CourseNotFound;
