import React, { useState, useEffect } from "react";
import Student from "./Student";
import { useHttp } from "../../hooks/useHttp";

const CourseStudents = ({ courseId }) => {
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(true);

  const {
    data: dataUserCourse,
    error: errorUserCourse,
    loading: loadingUserCourse,
    sendRequest: sendRequestUserCourse,
  } = useHttp();

  useEffect(() => {
    getCourseStudent();
  }, []);

  const getCourseStudent = async () => {
    sendRequestUserCourse(`/userCourses/${courseId}/course`);
    // if (courseId !== null) {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_API_URL}/userCourses/${courseId}/course`
    //   );
    //   const data = await response.json();
    //   console.log("users");
    //   console.log(data);
    //   setUsers(data.Cusers);
    // }
  };
  useEffect(() => {
    if (dataUserCourse) {
      setUsers(dataUserCourse);
    }
  }, [sendRequestUserCourse, dataUserCourse]);

  return (
    <div>
      <div className="text-2xl ml-4 my-2">Öğrenciler</div>
      {users.length > 0 ? (
        users.map((item) => <Student user={item} />)
      ) : (
        <div>Hic bir ogrenci bu kursa kayit olmamistir.</div>
      )}
    </div>
  );
};

export default CourseStudents;
