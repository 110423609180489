import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Courses = (props) => {
  const navigate = useNavigate();

  const { uid, name, description, course } = props;

  return (
    <>
      <div className="flex flex-wrap gap-6 p-6">
        <div className="flex-1 min-w-[100px] max-w-xs border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
          <div className="relative">
            <img
              src="https://picsum.photos/seed/picsum/300/300"
              className="w-full h-full object-cover rounded-t-lg"
              alt="..."
            />
          </div>
          <div className="flex flex-col justify-between p-4 h-auto">
            <div>
              <div className="flex gap-4">
                <h6 className="text-xl font-semibold text-gray-800 mb-2">
                  <span>{name}</span>
                </h6>
                <div className="text-green-400 text-4xl">{course.price} TL</div>
              </div>
              <p className="text-sm text-gray-500 overflow-hidden">
                {description}
              </p>
            </div>
            <div className="mt-4">
              <Link
                className="bg-gradient-to-r from-red-500 to-red-700 text-white py-2 px-4 sm:py-3 sm:px-6 rounded-md text-center block hover:from-red-600 hover:to-red-800 transition-all duration-300 text-sm sm:text-base"
                to={`/course/${uid}`}
              >
                İncele
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
