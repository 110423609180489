import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, NavLink, useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";

const ForgotPassword = () => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const params = useParams();
	const { id } = params;
	const authCtx = useContext(AuthContext);

	const { data, error, loading, sendRequest } = useHttp();

	const handleSubmit = (event) => {
		event.preventDefault();
		// Yeni şifre ve şifre onayı eşleşiyor mu kontrol et
		if (newPassword === confirmPassword) {
			const data = { newPassword };
			sendRequest(`/mail/new-password/${id}`, "POST", data);
			console.log("Password reset for:", "with new password:", newPassword);
		} else {
			// Hata mesajı göster
			alert("Şifre aynı değil");
		}
	};

	useEffect(() => {
		if (data != null && data.success == true) {
			authCtx.logout();
		}
	}, [data]);

	return (
		<div className='flex justify-center items-center h-screen bg-gray-100'>
			{data != null ? (
				<div>Hesabınıza mail gönderildi</div>
			) : (
				<div className='p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md'>
					<form onSubmit={handleSubmit}>
						<div className='mb-4'>
							<label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='newPassword'>
								Yeni Şifre
							</label>
							<input
								type='password'
								id='newPassword'
								className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								required
							/>
						</div>
						<div className='mb-6'>
							<label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='confirmPassword'>
								Yeni Şifreyi Onayla
							</label>
							<input
								type='password'
								id='confirmPassword'
								className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								required
							/>
						</div>
						<div className='flex items-center justify-between'>
							<button
								className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
								type='submit'
							>
								Şifreyi Sıfırla
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default ForgotPassword;
