import { PrimaryButton } from "@react-pdf-viewer/core";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";

const ProfileCourses = ({ item }) => {
	const authCtx = useContext(AuthContext);

	const { data, error, loading, sendRequest } = useHttp();

	const formatDate = (isoDate) => {
		if (isoDate == null) return "Başlangıç";
		const date = new Date(isoDate);
		return date.toLocaleDateString("tr-TR", {
			year: "numeric",
			month: "long",
			day: "numeric",
			// hour: '2-digit',
			// minute: '2-digit',
			// second: '2-digit',
		});
	};

	useEffect(() => {
		if (data) {
			console.log(data);
		}
	}, [sendRequest, data]);

	const handleMentorCourseEntrol = () => {
		const data = {
			isMentor: true,
		};
		sendRequest("/userCourseProgresses", "PUT", data);
	};

	const formatTime = (isoDate) => {
		if (isoDate == null) return "Başlangıç";
		const date = new Date(isoDate);
		return date.toLocaleString("tr-TR", {
			// year: 'numeric',
			// month: 'long',
			// day: 'numeric',
			hour: "2-digit",
			minute: "2-digit",
			// second: '2-digit',
		});
	};

	return (
		<>
			<div>
				<div className='grid grid-cols-1  lg:grid-cols-4 m-6 border-black border-solid rounded-md border-2'>
					<div className='col-span-1 border-black border-solid lg:border-r-2 pb-2'>
						<div className='flex justify-center '>
							<div className=' rounded-full border-blue-300 border-2 lg:border-0 p-5 m-2'>
								<h2 className='p-2 text-lg sm:text-xl flex justify-center'>{item.course.name}</h2>
								<div className='flex flex-row justify-center items-center gap-2'>
									<span className='text-xs'>Level</span>
									<h2 className='bg-green-200 w-auto h-16 sm:w-10 sm:h-auto rounded-full py-4 sm:py-8 px-6 sm:px-12 flex justify-center text-3xl sm:text-4xl font-medium'>
										{item.progress.levelSection == 1 && item.progress.levelChunk == 0 ? (
											<span>0</span>
										) : (
											<span>
												<strong>{item.progress.levelSection}</strong>/
												<span className='text-xl '>{item.progress.levelChunk}</span>
											</span>
										)}
									</h2>
								</div>
							</div>
						</div>
					</div>
					<div className='col-span-3'>
						<div className='flex flex-col lg:flex-row justify-evenly m-6'>
							<div className='flex flex-row lg:justify-start justify-center w-full'>
								<section className='mb-4 w-fit '>
									<p>
										Son giriş Tarihi: <strong>{formatDate(item.progress.lastEnteredDate)}</strong>
									</p>
									<p>
										Son giriş Zamanı: <strong>{formatTime(item.progress.lastEnteredDate)}</strong>
									</p>
									{/* <p>Son giris Saati: {item.progress.lastEnteredTime}</p> */}
									<p>
										Ünite: <strong>{item.progress.levelSection}</strong>
									</p>
									<p>
										Konu: <strong>{item.progress.levelChunk}</strong>
									</p>
								</section>
							</div>

							<div>
								{item.progress.courseComplete && !item.progress.isMentor ? (
									<button className='p-3 m-2 bg-blue-100 rounded-md text-sm'>
										<div className='btn btn-primary' onClick={handleMentorCourseEntrol}>
											Mentor Kursuna Kayıt ol
										</div>
									</button>
								) : item.progress.courseComplete && item.progress.isMentor ? (
									<div>Mentor kursunuzda başarılar.</div>
								) : (
									<></>
								)}
							</div>
							<div className='flex flex-row justify-center'>
								{item.progress.courseComplete ? (
									<button className='p-3 m-2 bg-blue-100 rounded-md text-sm'>
										<Link className='btn btn-primary' to={`/course/${item.course.uniqId}`}>
											Kursunuz tamamlanmıştır. Detay
										</Link>
									</button>
								) : (
									<button className='p-2 m-2 bg-green-100 rounded-md text-normal'>
										<Link className='btn btn-primary flex flex-col' to={`/course-detail/${item.course.uniqId}`}>
											<span className='w-40'>Bilgi Kazanım </span>
											<span className='font-bold w-40'>Detay ve Başlat</span>
										</Link>
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileCourses;
