import React, { useState, useEffect, useContext } from "react";

const NotFoundPage = () => {
	return (
		<>
			<div className=' '>
				<div className='flex flex-col justify-center items-center h-screen bg-yellow-50'>
					<div className='text-3xl'>Bizde böyle bir sayfa yok kanka, yada bir hata oluştu.</div>
					<div className='text-3xl'>Hatasız kul, errorsuz internet sayfası olmaz.</div>
					<div className='text-3xl'>Sen ne yapıyorsan en iyisi bi daha dene.</div>
				</div>
			</div>
		</>
	);
};

export default NotFoundPage;
