import React, { Fragment, useState, useEffect } from "react";
import Course from "../components/Course/Course";
import { useParams } from "react-router-dom";
import { useHttp } from "../hooks/useHttp";
import { data } from "autoprefixer";

function CoursesByCategoryId() {
  const params = useParams();
  const { uid } = params;
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: dataCourses,
    error: errorCourses,
    loading: loadingCourses,
    sendRequest: sendRequestCourses,
  } = useHttp();

  const getCourse = async () => {
    sendRequestCourses(`/courses/${uid}/category`);
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/courses/${id}/category`
    // );
    // const data = await response.json();
    // setCourses(data);
    // console.log(data);
    // setIsLoading(false);
  };

  useEffect(() => {
    if (dataCourses) {
      setCourses(dataCourses);
      console.log(dataCourses);
      setIsLoading(false);
    }
  }, [sendRequestCourses, dataCourses]);

  useEffect(() => {
    getCourse();
  }, [uid]);

  return (
    <Fragment>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <div className="bootstrap container">
          <div className="bg-red-500 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl m-4">
            <div className="flex flex-col justify-center">Kurslar</div>
          </div>
          <div className="card-list">
            {courses.length == 0 ? (
              <div className="flex flex-row justify-center text-3xl">{`İlgili kategoride kurs bulunamadı.`}</div>
            ) : (
              courses.map((course) => (
                <Course
                  key={course.uid}
                  uid={course.uid}
                  name={course.name}
                  description={course.description}
                />
              ))
            )}
            {}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CoursesByCategoryId;
