import React, { useState, useContext, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const CourseAdd = ({ setState, course, setCourse }) => {
  const [categoryId, setCategoryId] = useState(null);
  const [courseName, setCourseName] = useState(null);
  const [courseDesc, setCourseDesc] = useState(null);
  const [categories, setCategories] = useState([]);
  const [goal, setGoal] = useState("");
  const [savedGoals, setSavedGoals] = useState([]);
  const [plusGoal, setPlusGoal] = useState(1);

  const [forWho, setForWho] = useState("");
  const [savedForWho, setSavedForWho] = useState([]);
  const [plusForWho, setPlusForWho] = useState(1);

  const [courseTotalCompleteTimeType, setCourseTotalCompleteTimeType] =
    useState(null);
  const [courseTotalCompleteTimeNumber, setCourseTotalCompleteTimeNumber] =
    useState(null);

  const [chunkTimeType, setChunkTimeType] = useState(null);
  const [chunkTimeNumber, setChunkTimeNumber] = useState(null);
  const [courseTimeType, setCourseTimeType] = useState(null);
  const [courseTimeNumber, setCourseTimeNumber] = useState(null);

  const [courseActive, setCourseActive] = useState(false);
  const [mentorCourse, setMentorCourse] = useState(false);

  const {
    data: dataAddCourse,
    error: errorAddCourse,
    loading: loadingAddCourse,
    sendRequest: sendRequestAddCourse,
  } = useHttp();
  const {
    data: dataUpdateCourse,
    error: errorUpdateCourse,
    loading: loadingUpdateCourse,
    sendRequest: sendRequestUpdateCourse,
  } = useHttp();

  const {
    data: dataCategory,
    error: errorCategory,
    loading: loadingCategory,
    sendRequest: sendRequestCategory,
  } = useHttp();

  const addGoal = () => {
    if (goal.trim() !== "") {
      const newGoal = {
        order: savedGoals.length + plusGoal,
        goal: goal,
      };

      setSavedGoals([...savedGoals, newGoal]);

      // Clear fields
      setGoal("");
    } else {
      alert(`lutfen bos alanlari doldurunuz`);
    }
  };

  const handleGoalRemove = (ord) => {
    console.log(`remove calisti`);
    const removeWord = savedGoals.filter((item) => item.order !== ord);
    if (removeWord.length == 0) {
      setPlusGoal(1);
    } else {
      setPlusGoal((prev) => prev + 1);
    }

    setSavedGoals(removeWord);
  };

  //for who
  const addForWho = () => {
    if (forWho.trim() !== "") {
      const newForWho = {
        order: savedForWho.length + plusForWho,
        forWho: forWho,
      };

      setSavedForWho([...savedForWho, newForWho]);

      // Clear fields
      setForWho("");
    } else {
      alert(`lutfen bos alanlari doldurunuz`);
    }
  };

  const handleForWhoRemove = (ord) => {
    const removeForWho = savedForWho.filter((item) => item.order !== ord);
    if (removeForWho.length == 0) {
      setPlusForWho(1);
    } else {
      setPlusForWho((prev) => prev + 1);
    }
    setSavedForWho(removeForWho);
  };

  const handleCategoryId = (e) => {
    console.log(e.target.value);
    setCategoryId(e.target.value);
  };

  const handleCourseName = (e) => {
    console.log(e.target.value);
    setCourseName(e.target.value);
  };

  const handleCourseDesc = (e) => {
    console.log(e.target.value);
    setCourseDesc(e.target.value);
  };

  const handleCourseTotalTimeType = (e) => {
    console.log(e.target.value);
    setCourseTotalCompleteTimeType(e.target.value);
  };
  const handleCourseTotalTimeNumber = (e) => {
    console.log(e.target.value);
    setCourseTotalCompleteTimeNumber(e.target.value);
  };

  const handleChunkTimeType = (e) => {
    console.log(e.target.value);
    setChunkTimeType(e.target.value);
  };

  const handleChunkTimeNumber = (e) => {
    console.log(e.target.value);
    setChunkTimeNumber(e.target.value);
  };

  const handleCourseTimeType = (e) => {
    console.log(e.target.value);
    setCourseTimeType(e.target.value);
  };

  const handleCourseTimeNumber = (e) => {
    console.log(e.target.value);
    setCourseTimeNumber(e.target.value);
  };

  const toggleCheckbox = () => {
    setCourseActive(!courseActive);
  };

  const handleSubmitCourseAdd = async () => {
    const data = {
      categoryUniqId: categoryId,
      goals: savedGoals.length !== 0 ? JSON.stringify(savedGoals) : null,
      forWho: savedForWho.length !== 0 ? JSON.stringify(savedForWho) : null,
      name: courseName,
      description: courseDesc,
      totalCompleteTimeType: courseTotalCompleteTimeType,
      totalCompleteTimeNumber: courseTotalCompleteTimeNumber,
      chunkTimeType: chunkTimeType,
      chunkTimeNumber: chunkTimeNumber,
      courseTimeType: courseTimeType,
      courseTimeNumber: courseTimeNumber,
      mentorCourse: mentorCourse,
    };
    await sendRequestAddCourse("/courses", "POST", data);
    // fetch(`${process.env.REACT_APP_API_URL}/courses`, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     Authorization: `${authCtx.token}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => console.log(response.json()))
    //   .then((data) => {
    //     console.log("Success:", data);
    //     setState(false);
    //     console.log(`setsette false`);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
  };

  useEffect(() => {
    if (dataAddCourse) {
      setState(false);
      setCourse(dataAddCourse);
    }
  }, [dataAddCourse]);

  const handleSubmitCourseUpdate = () => {
    const data = {
      categoryUniqId: categoryId,
      goals: savedGoals.length !== 0 ? JSON.stringify(savedGoals) : null,
      forWho: savedForWho.length !== 0 ? JSON.stringify(savedForWho) : null,
      name: courseName,
      description: courseDesc,
      totalCompleteTimeType: courseTotalCompleteTimeType,
      totalCompleteTimeNumber: courseTotalCompleteTimeNumber,
      chunkTimeType: chunkTimeType,
      chunkTimeNumber: chunkTimeNumber,
      courseTimeType: courseTimeType,
      courseTimeNumber: courseTimeNumber,
      mentorCourse: mentorCourse,
    };
    console.log("updated data")
    console.log(data)
    sendRequestUpdateCourse(`/courses/${course.uniqId}`, "PUT", data);
    // console.log(data);

    // fetch(`${process.env.REACT_APP_API_URL}/courses/${course.id}`, {
    //   method: "PUT",
    //   mode: "cors",
    //   headers: {
    //     Authorization: `${authCtx.token}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => console.log(response.json()))
    //   .then((data) => {
    //     console.log("Success:", data);
    //     setState(false);
    //     // console.log("data");
    //     // console.log(data);
    //     // setCourse(data);
    //     console.log(`setsette false`);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
  };
  useEffect(() => {
    if (dataUpdateCourse) {
      setState(false);
      setCourse(dataUpdateCourse);
    }
  }, [dataUpdateCourse]);

  const getCategories = async () => {
    sendRequestCategory(`/categories`);
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/categories/`
    // );
    // const data = await response.json();
    // setCategories(data);
    // console.log(data);
    // // setIsLoading(false);
  };

  useEffect(() => {
    if (dataCategory) {
      setCategories(dataCategory);
    }
  }, [sendRequestCategory, dataCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (course != null) {
      console.log("course2");
      console.log(course);
      setCourseName(course.name);
      setCourseDesc(course.description);
      // setCategoryId(course.categoryId);
      if (course.goal !== null) {
        setSavedGoals(JSON.parse(course.goals));
      }
      if (course.forWho !== null) {
        setSavedForWho(JSON.parse(course.forWho));
      }
      //setSavedForWho(JSON.parse(course.forWho));

      setCourseTotalCompleteTimeType(course.totalCompleteTimeType);
      setCourseTotalCompleteTimeNumber(course.totalCompleteTimeNumber);

      setChunkTimeType(course.chunkTimeType);
      setChunkTimeNumber(course.chunkTimeNumber);
      setCourseTimeType(course.courseTimeType);
      setCourseTimeNumber(course.courseTimeNumber);
      setMentorCourse(course.mentorCourse);
      console.log("course.mentorCourse");
      console.log(course.mentorCourse);
    }if(categories.length > 0 && course !=null ) {
      console.log(categories)
      console.log(course)
      const selectedCategory=categories.find(item=>item.uniqId == course.category.uniqId)
      console.log("selected categori")
      console.log(selectedCategory);
      setCategoryId(selectedCategory.uniqId);
    }
  }, [course,categories]);

  return (
    <>
      {loadingAddCourse || loadingUpdateCourse ? (
        <div>
          <LocalSpinnerBar />
        </div>
      ) : (
        <div className="max-sm:flex-row bg-red-100 p-2 md:p-4 w-full">
          <div className="flex flex-col 2xl:flex-row justify-center gap-4">
            <div className="bg-blue-100 p-10 w-full max-md:w-full rounded-lg shadow-lg border-2 border-indigo-200">
              <h2 className="text-xl md:text-2xl font-semibold text-center pb-8">
                Kurs Başlangıç Kaydı
              </h2>

              <div className="flex flex-col gap-5 rounded-lg p-4">
                <div className="font-bold flex flex-row justify-between ">
                  <label className="w-32">Kategori:</label>
                  <select
                    name="kurs"
                    value={categoryId}
                    id="kurs"
                    className="p-2 flex-grow"
                    onChange={handleCategoryId}
                  >
                    <option value="yok">Bir kategori seçiniz</option>
                    {categories &&
                      categories.map((item) => (
                        <option key={item.uniqId} value={item.uniqId}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="flex flex-row justify-between font-bold">
                  <label className="w-32">Kurs Adı:</label>
                  <input
                    type="text"
                    placeholder="Please type course name..."
                    value={courseName}
                    onChange={handleCourseName}
                    className="p-2 flex-grow"
                  />
                </div>

                <div className="flex flex-row justify-between font-bold">
                  <label className="w-32">Kurs Açıklaması:</label>
                  <textarea
                    placeholder="Please type course description..."
                    onChange={handleCourseDesc}
                    value={courseDesc}
                    rows={5}
                    className="p-2 flex-grow"
                  />
                </div>
                <div className="flex flex-row justify-start font-bold">
                  <label className="w-32">Mentor kursu</label>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setMentorCourse(!mentorCourse);
                    }}
                    checked={mentorCourse}
                  />
                </div>
              </div>
            </div>
                    {/* Çalışma sıklığı */}
            <div className="bg-blue-100 p-4 w-full max-md:w-full rounded-lg shadow-lg border-2 border-indigo-200">
              <h2 className="text-xl md:text-2xl font-semibold text-center p-6">
                Kurs Çalışma Sıklığı
              </h2>
              <div className="flex flex-col justify-center gap-4 p-4 mt-10 rounded-lg">
                <div className="flex flex-col lg:flex-row lg:w-full border border-1 border-black p-1 gap-5 md:w-auto items-center ">
                  <div className=" font-bold flex flex-col justify-center text-center">
                    Kurs toplam zaman:{" "}
                  </div>
                  <div className="flex flex-col justify-center md:w-1/5 w-full items-center">
                    Toplam
                  </div>
                  <input
                    type="number"
                    value={courseTotalCompleteTimeNumber}
                    onChange={handleCourseTotalTimeNumber}
                    className="rounded-lg p-2 md:w-1/5 w-full min-w-40"
                  />
                  <select
                    className="rounded-lg p-2 md:w-1/5 w-full min-w-fit "
                    value={courseTotalCompleteTimeType}
                    onChange={handleCourseTotalTimeType}
                  >
                    <option value="yok">Zaman Secin</option>
                    <option value="gunde">Gunde</option>
                    <option value="haftada">Haftada</option>
                    <option value="ayda">Ayda</option>
                  </select>
                  <div className="flex flex-col justify-center md:w-1/5 w-full max-md:w-auto break-words items-center">
                    tamamlanacaktır.
                  </div>
                </div> 
                <div className="flex flex-col lg:flex-row gap-5 p-1 lg:w-full max-md:w-full border border-1 border-black items-center">
                  <div className="font-bold flex flex-col justify-center md:w-1/5 w-full text-center">
                    Çalışma düzeni:{" "}
                  </div>
                  <div className="flex flex-col justify-center md:w-1/5 w-full items-center">
                    Her
                  </div>
                  <select
                    className="rounded-lg p-2 md:w-1/5 w-full min-w-fit"
                    value={courseTimeType}
                    onChange={handleCourseTimeType}
                  >
                    <option value="yok">Zaman Secin</option>
                    <option value="gunde">Gunde</option>
                    <option value="haftada">Haftada</option>
                    <option value="ayda">Ayda</option>
                  </select>
                  <input
                    type="number"
                    value={courseTimeNumber}
                    onChange={handleCourseTimeNumber}
                    className="rounded-lg p-2 md:w-1/5 w-full "
                  />
                  <div className="flex flex-col justify-center md:w-1/5 w-full items-center text-center">
                    defa çalışılacaktır.
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-5 md:w-auto border border-1 border-black items-center">
                  <div className="font-bold flex flex-col justify-center md:w-1/5 w-full text-center">
                    Çalışma Sıklığı:{" "}
                  </div>
                  <div className="flex flex-col justify-center md:w-1/5 w-full items-center">
                    Bir kerede{" "}
                  </div>
                  <input
                    type="number"
                    value={chunkTimeNumber}
                    onChange={handleChunkTimeNumber}
                    className="rounded-lg p-2 md:w-1/5 w-full"
                  />
                  <select
                    className="rounded-lg p-2 md:w-1/5 w-full min-w-fit"
                    value={chunkTimeType}
                    onChange={handleChunkTimeType}
                  >
                    <option value="yok">Zaman Secin</option>
                    <option value="Saat">Saat</option>
                    <option value="Dakika">Dakika</option>
                    {/* <option value='Gun'>Gun</option>
            <option value='Hafta'>Hafta</option>
            <option value='Ay'>Ay</option> */}
                  </select>
                  <div className="flex flex-col justify-center md:w-1/5 w-full items-center">
                    çalışılacaktır.
                  </div>
                </div>
                <div className="border border-2 border-indigo-200 m-2 p-2 rounded-lg bg-green-100 w-1/4 md:w-auto break-words">
                  <div className="font-bold p-2">Açıklama : </div>
                  {courseTimeNumber && courseTimeType && (
                    <div>
                      {" "}
                      (Course) çalışma şekli:{" "}
                      <span className="font-bold">
                        {" "}
                        Her {courseTimeType} {courseTimeNumber} defa çalışılması
                        gerekiyor.
                      </span>
                    </div>
                  )}
                  {chunkTimeNumber && chunkTimeType && (
                    <div>
                      {" "}
                      Bir defada:{" "}
                      <span className="font-bold">
                        {" "}
                        {chunkTimeNumber} {chunkTimeType} çalışılacaktır.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col 2xl:flex-row justify-between gap-4 p-4">
            <div className="bg-blue-100 p-4 w-full  shadow-lg border border-indigo-200 rounded-lg">
              <div className="flex flex-row gap-2">
                <div className="container p-4 border border-2 border-indigo-200 m-7 w-96 shadow-lg bg-blue-50 sm:flex-col">
                  <h2 className="text-2xl font-bold mb-4 text-center">
                    Kurs Hedefleri{" "}
                  </h2>

                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Hedef Giriniz :
                    </label>
                    <textarea
                      type="text"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={goal}
                      onChange={(e) => setGoal(e.target.value)}
                    />
                  </div>

                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                    onClick={addGoal}
                  >
                    Ekle
                  </button>
                </div>
                <div className=" p-2 m-2 flex sm:flex-col w-full">
                  <ul className="mt-4 mb-4 p-1">
                    {savedGoals.map((goal, index) => (
                      <li
                        key={index}
                        className="flex flex-row bg-gray-100 p-4 mt-2 border border-indigo-200 rounded-md  justify-between"
                      >
                        <div className=" p-2">
                          <span>
                            {/* <strong>Sıra: </strong> {goal.order}, */}
                            <strong>Hedef: </strong> {goal.goal}
                          </span>
                        </div>
                        <div className=" p-1 sm:flex-col">
                          <span
                            className="w-auto p-2 bg-red-500 text-white font-bold flex flex-col justify-center text-center cursor-pointer rounded-lg hover:bg-red-600"
                            onClick={() => handleGoalRemove(goal.order)}
                          >
                            {" "}
                            Çıkar{" "}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="bg-blue-100 p-4 w-full border border-indigo-200 shadow-lg rounded-lg">
              {" "}
              <div className="flex flex-row  gap-2">
                <div className="container p-4 m-7 w-96 border border-indigo-200 shadow-lg rounded-lg bg-blue-50">
                  <h2 className="text-2xl font-bold mb-4 text-center ">
                    Kursa Kimler Katılabilir{" "}
                  </h2>

                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Kriter Giriniz :
                    </label>
                    <textarea
                      type="text"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={forWho}
                      onChange={(e) => setForWho(e.target.value)}
                    />
                  </div>

                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                    onClick={addForWho}
                  >
                    Ekle
                  </button>
                </div>
                <div className=" p-2 m-2 w-full">
                  <ul className="mt-4 mb-4">
                    {savedForWho.map((item, index) => (
                      <li
                        key={index}
                        className="flex flex-row bg-gray-100 p-4 mt-2 border border-indigo-200 rounded-md flex flex-row justify-between w-auto"
                      >
                        <div className=" p-2 m-2">
                          <span>
                            {/* <strong>sira:</strong> {item.order}, */}
                            <strong>Kriter:</strong> {item.forWho}
                          </span>
                        </div>
                        <div className=" p-1">
                          <span
                            className="w-auto p-2 bg-red-500 text-white font-bold flex flex-col justify-center text-center cursor-pointer rounded-lg hover:bg-red-600"
                            onClick={() => handleForWhoRemove(item.order)}
                          >
                            {" "}
                            Çıkar{" "}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {course !== null ? (
            <div className="w-22 flex flex-row justify-center">
              <button
                className="bg-green-200 p-2 w-32 rounded-md"
                onClick={handleSubmitCourseUpdate}
              >
                Güncelle
              </button>
            </div>
          ) : (
            <div className="w-22 flex flex-row justify-center">
              <button
                className="text-white font-bold bg-green-500 p-2 w-32 rounded-md hover:bg-green-600"
                onClick={handleSubmitCourseAdd}
              >
                Kaydet
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CourseAdd;
