import React, { Fragment, useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useHttp } from "../../hooks/useHttp";
import MentorCourseProgress from "./MentorCourseProgress";
import MentorMenties from "./MentorMenties";

const MentorProgress = () => {
  const [course, setCourse] = useState(null);
  const [progress, setProgress] = useState(null);
  const [courseGoals, setGoals] = useState([]);
  const params = useParams();
  const { uid } = params;
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    getLastChunkItems();
  }, []);

  const {
    data: dataUserourseProgress,
    error: errorUserourseProgress,
    loading: loadingUserourseProgress,
    sendRequest: sendRequestUserCourseProgress,
  } = useHttp();

  async function getLastChunkItems() {
    await sendRequestUserCourseProgress(`/userCourseProgresses/${uid}`);
  }
  useEffect(() => {
    if (dataUserourseProgress) {
      setCourse(dataUserourseProgress);
      setProgress(dataUserourseProgress.progress);
    }
  }, [sendRequestUserCourseProgress, dataUserourseProgress]);

  return (
    <>
      {course !== null && !loading ? (
        <>
          {progress !== null && progress.isMentor == false ? (
            <MentorMenties courseUniqId={course.uniqId} />
          ) : (
            <MentorCourseProgress course={course} />
          )}
        </>
      ) : course == null && !loading ? (
        <div>
          <h1>Kurs yok</h1>
        </div>
      ) : (
        <div>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default MentorProgress;
