import React, { useState, useEffect } from "react";
import SectionAdd from "./SectionAdd";
import SectionTek from "./SectionTek";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Modal from "../UI/Modal";
import { useHttp } from "../../hooks/useHttp";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const Sections = ({ courseUniqId }) => {
  const [newSectionShow, setNewSectionShow] = useState(false);
  const [sections, setSections] = useState([]);
  const [section, setSection] = useState(null);

  const {
    data: dataSections,
    error: errorSections,
    loading: loadingSections,
    sendRequest: sendRequestSections,
  } = useHttp();

  useEffect(() => {
    getCourseSections();
  }, [newSectionShow]);

  const getCourseSections = async () => {
    sendRequestSections(`/sections/${courseUniqId}/course`);
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/sections/${courseId}/course`
    // );
    // const data = await response.json();
    // setSections(data);
    // console.log(`sections with courseid ${courseId}`);
    // console.log(data);
  };

  useEffect(() => {
    if (dataSections) {
      console.log("dataSections")
      console.log(dataSections)
       setSections(dataSections);
    }
  }, [dataSections]);

  useEffect(() => {
    if (section !== null) {
      setNewSectionShow(true);
    }
  }, [section]);

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2">
        <div className="border border-l-red-400"></div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col mx-1">
            <div className="flex justify-center">
              <div className="text-3xl my-10 flex flex-row gap-3">
                Üniteler
                <span
                  className="flex flex-col justify-end cursor-pointer"
                  onClick={() => {
                    setSection(null);
                    setNewSectionShow(true);
                  }}
                >
                  <PlusCircleIcon className="text-red-400 h-6 w-6" />
                </span>
              </div>
            </div>
            <div>
              {sections.length > 0 && !loadingSections ? (
                sections.map((item) => (
                  <SectionTek item={item} setSection={setSection} />
                ))
              ) : sections.length == 0 && !loadingSections ? (
                <div className="m-4 p-4">
                  Lütfen artıya basarak ünite ekleyiniz.
                </div>
              ):<LocalSpinnerBar/>}
            </div>
          </div>
          <div className="flex flex-col mt-4 md:mt-0">
            <div className="flex flex-row justify-center">
            </div>
            {newSectionShow && (
              <Modal
                onClose={() => {
                  setNewSectionShow(false);
                  setSection(null);
                }}
                title={
                  section != null
                    ? `${section.orderNo}- Ünite Güncelle`
                    : `Ünite Ekleme`
                }
              >
                <SectionAdd
                  setNewSectionShow={setNewSectionShow}
                  courseUniqId={courseUniqId}
                  section={section}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sections;
