import React, { useState, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const Category = () => {
  // State to manage category list and new category
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const {
    data: dataCategories,
    error: errorCategories,
    loading: loadingCategories,
    sendRequest: sendRequestCategories,
  } = useHttp();
  const {
    data: dataAddCategory,
    error: errorAddCategory,
    loading: loadingAddCategory,
    sendRequest: sendRequestAddCategory,
  } = useHttp();

  // Fetch categories from backend on component mount
  useEffect(() => {
    sendRequestCategories("/categories");
  }, []);

  useEffect(() => {
    if (Array.isArray(dataCategories)) {
      setCategories(dataCategories);
    }
  }, [dataCategories]);

  // Function to handle new category form submission
  const handleAddCategory = (e) => {
    e.preventDefault();
    sendRequestAddCategory(`/categories`, "POST", { name: newCategory });
    setNewCategory("");
  };

  return (
    <div>
      <h2>Categories</h2>
      {categories.length != 0 && !loadingCategories ? (
        <div>
          <ul>
            {categories.map((category) => (
              <li
                key={category.uniqId}
                className="bg-red-300 border-1 border-black w-fit p-2 rounded-xl m-3"
              >
                {category.name}
              </li>
            ))}
          </ul>
        </div>
      ) : categories.length == 0 && !loadingCategories ? (
        <div>Kategori bulunmadı vey yok.</div>
      ) : (
        <div>
          <LocalSpinnerBar />
        </div>
      )}

      <form onSubmit={handleAddCategory}>
        <label>
          New Category:
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            required
          />
        </label>
        <button type="submit">Add Category</button>
      </form>
    </div>
  );
};

export default Category;
