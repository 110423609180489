import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useHttp } from "../../../hooks/useHttp";

const WordContent = ({ chunkItem }) => {
  const [word, setWord] = useState("");
  const [meaning, setMeaning] = useState("");
  const [exampleSentence, setExampleSentence] = useState("");
  const [exampleSentenceMeaning, setExampleSentenceMeaning] = useState("");
  const [savedWords, setSavedWords] = useState([]);
  const [arti, setArti] = useState(1);

  const [wordChunkItem, setWordChunkItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    data: dataWordContentAdd,
    error: errorWordContentAdd,
    loading: loadingWordContenAdd,
    sendRequest: sendRequestWordContentAdd,
  } = useHttp();

  const {
    data: dataWordDelete,
    error: errorWordDelete,
    loading: loadingWordDelete,
    sendRequest: sendRequestWordDelete,
  } = useHttp();

  useEffect(() => {
    if (chunkItem.wordContent !== null) {
      setLoading(true);
      getWords(chunkItem.wordContent.link);
    } else {
      setWordChunkItem(null);
      setWord("");
      setMeaning("");
      setExampleSentence("");
      setExampleSentenceMeaning("");
      setSavedWords([]);
      setArti(1);
    }
    console.log("Word chunkItem");
    console.log(chunkItem);
  }, [chunkItem]);

  const getWords = async (link) => {
    console.log(`link`);
    console.log(link);
    const response = await fetch(link);
    const data = await response.json();
    setWordChunkItem(chunkItem.wordContent);
    setSavedWords(JSON.parse(data));
    setLoading(false);
  };

  const addWord = () => {
    if (
      word.trim() !== "" &&
      meaning.trim() !== "" &&
      exampleSentence.trim() !== "" &&
      exampleSentenceMeaning.trim() !== ""
    ) {
      const newWord = {
        order: savedWords.length + arti,
        word: word,
        meaning: meaning,
        exampleSentence: exampleSentence,
        exampleSentenceMeaning: exampleSentenceMeaning,
      };

      setSavedWords([...savedWords, newWord]);
    } else {
      alert(`lutfen bos alanlari doldurunuz`);
    }
  };
  const handleWordRemove = (ord) => {
    console.log(`remove calisti`);
    const removeWord = savedWords.filter((item) => item.order !== ord);
    if (removeWord.length == 0) {
      setArti(1);
    } else {
      setArti((prev) => prev + 1);
    }

    setSavedWords(removeWord);
  };

  const saveWords = () => {
    setLoading(true);
    const data = {
      title: `Kelime`,
      content: JSON.stringify(savedWords),
      chunkItemUniqId: chunkItem.uniqId,
    };
    sendRequestWordContentAdd(`/wordContents`, "POST", data);

    // console.log(data);

    // fetch(`${process.env.REACT_APP_API_URL}/wordContents`, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     setWordChunkItem(response);
    //     setSavedWords(savedWords);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
    // setLoading(false);
  };
  useEffect(() => {
    if (dataWordContentAdd) {
      setSavedWords(savedWords);
    }
  }, [dataWordContentAdd]);

  const deleteHandler = async () => {
    sendRequestWordDelete(`/wordContents/${wordChunkItem.uniqId}`, "DELETE");
    // fetch(`${process.env.REACT_APP_API_URL}/wordContents/${wordChunkItem.id}`, {
    //   method: "delete",
    //   mode: "cors",
    //   headers: {
    //     // Authorization: `${authCtx.token}`,
    //     "Content-Type": "application/json",
    //   },
    //   //body: JSON.stringify(data),
    // })
    //   //.then((response) => console.log(response.json()))
    //   .then((response) => {
    //     console.log("Success:", response);
    //     setWordChunkItem(null);
    //     setWord("");
    //     setMeaning("");
    //     setExampleSentence("");
    //     setExampleSentenceMeaning("");
    //     setSavedWords([]);
    //     setArti(1);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
    // setLoading(false);
  };

  useEffect(() => {
    if (dataWordDelete) {
      setWordChunkItem(null);
      setWord("");
      setMeaning("");
      setExampleSentence("");
      setExampleSentenceMeaning("");
      setSavedWords([]);
      setArti(1);
    }
  }, [dataWordDelete]);

  return (
    <>
      {loading == true ? (
        <div>Loading</div>
      ) : wordChunkItem !== null ? (
        <div className=" p-2 m-2">
          <div
            className="flex bg-red-300 w-fit cursor-pointer"
            onClick={deleteHandler}
          >
            Delete
            <span>
              <XMarkIcon className="h-6 w-6" />
            </span>
          </div>
          <ul className="mt-4 mb-4">
            Kelimeler
            {savedWords.map((word, index) => (
              <li
                key={index}
                className="bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between"
              >
                <span>
                  <strong>sira:</strong> {word.order},<strong>Word:</strong>{" "}
                  {word.word}, <strong>Meaning:</strong> {word.meaning},{" "}
                  <strong>Example Sentence:</strong> {word.exampleSentence},
                  <strong>Example Sentence Meaning:</strong>{" "}
                  {word.exampleSentenceMeaning}
                </span>
                {/* <span
									className='w-auto p-2 bg-red-200 flex flex-col justify-center text-center cursor-pointer'
									onClick={() => handleWordRemove(word.order)}
								>
									Cikar{" "}
								</span> */}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="flex flex-row gap-2">
          <div className="container p-4 border border-red-200 m-7 w-96">
            <h2 className="text-2xl font-bold mb-4">Kelime öğrenme Kartı</h2>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Word:
              </label>
              <input
                type="text"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                value={word}
                onChange={(e) => setWord(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Meaning:
              </label>
              <input
                type="text"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                value={meaning}
                onChange={(e) => setMeaning(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Example Sentence:
              </label>
              <input
                type="text"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                value={exampleSentence}
                onChange={(e) => setExampleSentence(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Example Sentence Meaning:
              </label>
              <input
                type="text"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                value={exampleSentenceMeaning}
                onChange={(e) => setExampleSentenceMeaning(e.target.value)}
              />
            </div>

            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              onClick={addWord}
            >
              Ekle
            </button>
          </div>
          <div className=" p-2 m-2">
            <ul className="mt-4 mb-4">
              {savedWords.map((word, index) => (
                <li
                  key={index}
                  className="bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between"
                >
                  <span>
                    <strong>sira:</strong> {word.order},<strong>Word:</strong>{" "}
                    {word.word}, <strong>Meaning:</strong> {word.meaning},{" "}
                    <strong>Example Sentence:</strong> {word.exampleSentence},
                    <strong>Example Sentence Meaning:</strong>{" "}
                    {word.exampleSentenceMeaning}
                  </span>
                  <span
                    className="w-auto p-2 bg-red-200 flex flex-col justify-center text-center cursor-pointer"
                    onClick={() => handleWordRemove(word.order)}
                  >
                    {" "}
                    Cikar{" "}
                  </span>
                </li>
              ))}
            </ul>
            {savedWords.length !== 0 && (
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                onClick={saveWords}
              >
                Kaydet
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WordContent;
