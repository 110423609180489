import React, { useEffect, useState, useContext } from "react";
const Video = ({ item }) => {

	return (
		<>
			<div className="p-2 m-2">
				<h2 className="bg-green-200 w-fit">
					<strong>Yönerge :</strong> Lütfen aşağıdaki videoyu izleyiniz ve sonra
					sıradaki faliyete geçiniz.
				</h2>
			</div>
			<div className="flex items-center justify-center p-4">
				<div className="p-1 bg-gray-100 rounded shadow-lg w-full max-w-screen-md">
					<div className="relative pb-[56.25%] h-0 mx-auto">
						<video
							className="absolute top-0 left-0 w-full h-full object-cover rounded"
							controls
						>
							<source src={item.videoContent?.url || ""} type="video/mp4" />
							URL kaybolmuş galiba.
						</video>
					</div>
				</div>
			</div>
		</>
	);
};
export default Video;
