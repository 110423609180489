import React, { useContext } from "react";
import Layout from "../src/components/Layout";
import { Routes, Route, useLocation } from "react-router-dom";
import CoursePage from "./pages/CoursePage";
import CoursesByCategoryId from "./pages/CoursesByCategoryId";
import CoursesBySearchKey from "./pages/CoursesBySearchKey";
import ProfilePage from "./pages/ProfilePage";
import MainPage from "./pages/MainPage";
import PaymentPage from "./pages/PaymentPage";
import MentorPage from "./pages/MentorPage";
import MentorProgress from "./components/Mentor/MentorProgress";
import CourseDetailPage from "./pages/CourseDetailPage";
import ProfileSettings from "./components/Profile/ProfileSettings";
import TeacherCourses from "./pages/TeacherCourses";
import AuthForm from "./components/Auth/AuthForm";
import CourseProgress2 from "./components/Course/CourseProgress2";
import CourseStart from "./components/Course/CourseStart";
import Footer from "./components/Footer";
import AdminPage from "./pages/AdminPage";
import ConfirmAccountHeader from "./components/AccountSetting/ConfirmAccountHeader";
import ConfirmAccountMail from "./components/AccountSetting/ConfirmAccountMail";
import ForgotPassword from "./components/AccountSetting/ForgotPassword";
import NewPassword from "./components/AccountSetting/NewPassword";
import TeacherApplicationForm from "./components/Admin/TeacherApplicationForm";
import EksDetail from "./components/EKS/EksDetail";
import NotFoundPage from "./pages/NotFoundPage";
import EksPage from "./pages/EksPage";
import ContactPage from "./pages/ContactPage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	if (process.env.NODE_ENV === "production") {
		console.log("Running in production mode");
	} else {
		console.log("Running in development mode");
	}
	const location = useLocation();
	return (
		<div>
			{!location.pathname.includes("/course-start") ? (
				<>
					<ConfirmAccountHeader />
					<Layout />
					<ToastContainer position="top-left" />
				</>
			) : (
				<></>
			)}

			<div className='min-h-screen'>
				<Routes>
					<Route path='/' element={<MainPage />} />
					<Route path='/auth' element={<AuthForm />} />
					<Route path='/courses' element={<CoursePage />} />
					<Route path='/course/:uid' element={<CourseDetailPage />} />
					<Route path='/course-detail/:uid' element={<CourseProgress2 />} />
					<Route path='/course-start/:uid' element={<CourseStart />} />
					<Route path='/courses/:uid/category' element={<CoursesByCategoryId />} />
					<Route path='/courses/:key/search' element={<CoursesBySearchKey />} />
					<Route path='/profile' element={<ProfilePage />} />
					<Route path='/contact' element={<ContactPage />} />
					<Route path='/mentors' element={<MentorPage />} />
					<Route path='/mentor-course-detail/:uid' element={<MentorProgress />} />
					<Route path='/payment' element={<PaymentPage />} />
					<Route path='/profileSettings' element={<ProfileSettings />} />
					<Route path='/teacherCourses' element={<TeacherCourses />} />
					<Route path='/admin' element={<AdminPage />} />
					<Route path='/confirm-account/:id' element={<ConfirmAccountMail />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/new-password/:id' element={<NewPassword />} />
					<Route path='/teacher-application' element={<TeacherApplicationForm />} />
					{/* <Route path='/eks' element={<EksPage />} /> */}
					{/* <Route path='/eks/:id' element={<EksDetail />} /> */}
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			</div>
			{!location.pathname.includes("/course-start") ? (
				<>
					<Footer />
				</>
			) : (
				<></>
			)}
		</div>
		// <div>Selam AWS</div>
	);
}

export default App;
