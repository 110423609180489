import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";

const MentorCourse = ({ mycourse }) => {
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState();

  const {
    data: dataCourse,
    error: errorCourse,
    loading: loadingCourse,
    sendRequest: sendRequestCourse,
  } = useHttp();

  const {
    data: dataUser,
    error: errorUser,
    loading: loadingUser,
    sendRequest: sendRequestUser,
  } = useHttp();

  useEffect(() => {
    getCourses();
  }, []);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(authCtx);
  }, [authCtx]);

  useEffect(() => {
    getCourses();
  }, []);

  const handleCourse = (uid) => {
    let selectedCourse = courses.find((item) => item.uniqId == uid);
    if (selectedCourse !== undefined) {
      setCourse(selectedCourse);
    } else setCourse(null);
  };

  const handleMentorCourse = () => {
    if (course != null) {
      const data = {
        mentoringCourseUniqId: course.uniqId,
      };

      sendRequestCourse(`/courses/${mycourse.uniqId}`,"PUT",data);
      //   console.log("data");
      //   console.log(data);
      //   console.log("mycourse");
      //   console.log(mycourse);

      //   fetch(`${process.env.REACT_APP_API_URL}/courses/${mycourse.id}`, {
      //     method: "PUT",
      //     mode: "cors",
      //     headers: {
      //       Authorization: `${authCtx.token}`,
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(data),
      //   })
      //     .then((response) => console.log(response.json()))
      //     .then((data) => {
      //       console.log("Success:", data);
      //       console.log(`data`);
      //       console.log(data);
      //     })
      //     .catch((error) => {
      //       console.error("ErrorBurdan:", error);
      //     });
    } else {
      console.log("kurs bos");
    }
  };

  const getCourses = async () => {
    sendRequestUser(`/userCourses/user`);
    // if (authCtx.token !== null) {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_API_URL}/userCourses/user`,
    //     {
    //       method: "GET",
    //       headers: {
    //         Authorization: `${authCtx.token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   const data = await response.json();
    //   console.log("courses");
    //   console.log(data);
    //   setCourses(data);

    //   let abc = data.find((item) => item.id === mycourse.mentoringCourseId);
    //   console.log("abc");
    //   console.log(abc);
    //   setCourse(abc);
    // }
  };

  useEffect(() => {
    if (dataUser) {
      setCourses(dataUser);
    }
  }, [sendRequestUser, dataUser]);

  return (
    <>
      {mycourse.mentoringCourseId !== null ? (
        <div>{course && <div>Kurs adı : {course.name}</div>}</div>
      ) : (
        <div className="flex">
          <select
            onChange={(e) => handleCourse(e.target.value)}
            className="p-2 border border-green-400 rounded-lg bg-blue-50"
          >
            <option value="0">Lütfen mentor kursunu seçiniz</option>
            {courses.map((item) => (
              <option value={item.uniqId}>{item.name}</option>
            ))}
          </select>
          <button onClick={handleMentorCourse}>Kaydet</button>
        </div>
      )}
    </>
  );
};

export default MentorCourse;
