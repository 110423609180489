import React, { useEffect, useState } from "react";
import { useHttp } from "../../hooks/useHttp";

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const { data, error, loading, sendRequest } = useHttp();

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = { email };

		sendRequest("/mail/forgot-password", "POST", data);
	};

	return (
		<div className='flex justify-center items-center h-screen bg-gray-100'>
			<div className='p-6 max-w-sm s-auto bg-white rounded-xl shadow-md'>
				{data != null ? (
					<div>Hesabınıza mail gönderildi</div>
				) : (
					<form onSubmit={handleSubmit}>
						<div className='mb-4'>
							<label className='text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
								Email Address
							</label>
							<input
								type='email'
								id='email'
								className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>
						<div className='flex items-center justify-between'>
							<button
								className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
								type='submit'
							>
								Reset Password
							</button>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};

export default ForgotPassword;
