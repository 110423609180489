import React, { useState, useEffect } from "react";
import Sections from "./Sections";

const CourseTek = (props) => {
	let {
		name,
		description,
		totalCompleteTimeType,
		totalCompleteTimeNumber,
		courseTimeType,
		courseTimeNumber,
		chunkTimeType,
		chunkTimeNumber,
		categoryId,
		id,
		uniqId
	} = props.course;
	const [showSection, setShowSection] = useState(true);

	// useEffect(()=>{
	// 	console.log("props.course")
	// 	console.log(props.course)
	// },[props])

	return (
		<>
			<div className='mt-10'>
				<div
					className='m-4 border border-gray-300 bg-blue-800 rounded-xl flex flex-row p-2 text-white'
					onClick={() => {
						setShowSection(!showSection);
					}}
				>
					<div className='w-auto'>
						<div className='text-3xl p-2 ml-5'>{name}</div>
						<div className='italic ml-5 p-2'>{description}</div>
					</div>
				</div>
				{showSection && (
					<>
						{" "}
						<Sections courseUniqId={uniqId} />
						<div className='h-10 bg-red-400 rounded-lg py-5 my-10 flex flex-row justify-center'>
							<div className='flex flex-col justify-center'>Bitti</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default CourseTek;
