import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import CourseContent from "../components/TeacherCourse/CourseContent/CourseContent";
import CourseRules from "../components/TeacherCourse/CourseRules";
import CourseStudents from "../components/TeacherCourse/CourseStudents";
import CourseAdd from "../components/TeacherCourse/CourseAdd";
import CourseTek from "../components/TeacherCourse/CourseTek";
import CourseTeacherDetail from "../components/TeacherCourse/CourseTeacherDetail";
import CoursePublish from "../components/TeacherCourse/CoursePublish";
import MentorCourse from "../components/TeacherCourse/MentorCourse";
import { useHttp } from "../hooks/useHttp";

const TeacherCourses = () => {
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState(null);
  const [showPage, setShowPage] = useState(1);
  const [showPage1, setShowPage1] = useState(true);

  const {
    data: dataUser,
    error: errorUsers,
    loading: loadingUsers,
    sendRequest: sendRequestUsers,
  } = useHttp();

  useEffect(() => {
    setShowPage(1);
    getCourses();
  }, [showPage1]);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(authCtx);
  }, [authCtx]);

  useEffect(() => {
    getCourses();
  }, []);

  const handleCourse = (uniqId) => {
    let selectedCourse = courses.find((item) => item.uniqId == uniqId);
    // console.log("courses")
    // console.log(courses)
    // console.log("selectedCourse")
    // console.log(selectedCourse)
    if (selectedCourse !== undefined) {
      setCourse(selectedCourse);
      setShowPage(1);
    } else {
      setCourse(null);
      setShowPage(0);
    }
  };

  const getCourses = async () => {
    sendRequestUsers(`/userCourses/user`);
    // if (authCtx.token !== null) {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_API_URL}/userCourses/user`,
    //     {
    //       method: "GET",
    //       headers: {
    //         Authorization: `${authCtx.token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   const data = await response.json();
    //   console.log("courses");
    //   console.log(data);
    //   setCourses(data);
    // }
  };
  useEffect(() => {
    if (dataUser) {
      setCourses(dataUser);
    }
  }, [sendRequestUsers, dataUser]);

  return (
    <div className="flex flex-col lg:flex-row gap-4 p-4">
      <div className="flex-none w-full lg:w-72 h-auto bg-blue-100 rounded-lg shadow-md p-3 sm:p-4">
        <div>
          {courses.length === 0 ? (
            <div className="flex flex-row justify-center items-center gap-4">
              <div
                onClick={() => setShowPage(6)}
                className="text-black font-semibold cursor-pointer hover:underline"
              >
                Lütfen kurs ekleyin
              </div>
              <div
                className="text-2xl px-4 py-2 bg-red-300 text-white font-bold rounded-lg cursor-pointer hover:bg-red-400"
                onClick={() => setShowPage(6)}
              >
                {course != null ? "!" : "+"}
              </div>
            </div>
          ) : (
            <div className="flex flex-row cursor-pointer gap-1">
              <select
                onChange={(e) => handleCourse(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg bg-white shadow-sm focus:ring-2 focus:ring-blue-500"
              >
                <option value="0">Lütfen kurs seçiniz</option>
                {courses.map((item) => (
                  <option value={item.uniqId}>{item.name}</option>
                ))}
              </select>
              <div
                className="text-2xl px-3 bg-red-400 rounded-lg"
                onClick={() => setShowPage(6)}
              >
                {course != null ? "!" : "+"}
              </div>
            </div>
          )}
        </div>
        {course !== null ? (
          <div className="flex flex-col mt-3 justify-center">
            <div
              onClick={() => setShowPage(1)}
              className={`p-3 rounded-lg text-center font-semibold cursor-pointer ${
                showPage === 1
                  ? "bg-blue-500 text-white shadow-md"
                  : "bg-gray-100 hover:bg-blue-200"
              }`}
            >
              Tanıtım
            </div>
            <div
              onClick={() => setShowPage(2)}
              className={`p-1 ${showPage === 2 ? "bg-blue-400" : ""}`}
            >
              Kapsam
            </div>
            <div
              onClick={() => setShowPage(3)}
              className={`p-1 ${showPage === 3 ? "bg-blue-400" : ""}`}
            >
              İçerikler
            </div>
            <div
              onClick={() => setShowPage(4)}
              className={`p-1 ${showPage === 4 ? "bg-blue-400" : ""}`}
            >
              Kurallar
            </div>
            <div
              onClick={() => setShowPage(5)}
              className={`p-1 ${showPage === 5 ? "bg-blue-400" : ""}`}
            >
              Öğrenciler
            </div>

            {course.mentorCourse != true && (
              <>
                <div
                  onClick={() => setShowPage(7)}
                  className={`p-1 ${showPage === 7 ? "bg-blue-400" : ""}`}
                >
                  Yayinlama
                </div>
                <div
                  onClick={() => setShowPage(8)}
                  className={`p-1 ${showPage === 8 ? "bg-blue-400" : ""}`}
                >
                  Mentor Course
                </div>
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex w-full">
        {showPage === 0 && (
          <div className="flex-1 bg-green-100 justify-center items-center">
            <div className="flex flex-row gap-3 justify-center mt-20 font-bold text-2xl bg-green-100 p-6 rounded-lg">
              <div className="flex h-screen">Zincir Okula Hoş Geldiniz</div>
            </div>
          </div>
        )}
        {showPage === 1 && (
          <div className="flex-1 bg-green-100 flex justify-center items-center">
            {course !== null ? (
              <CourseTeacherDetail course={course} />
            ) : (
              <div className="flex flex-col sm:flex-row justify-center items-center text-center font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl p-4 sm:p-6">
                Zincir Okula Hoş Geldiniz
              </div>
            )}
          </div>
        )}
        {showPage === 2 && (
          <div className="flex-1 bg-yellow-50">
            {course === null ? (
              <div>Lütfen kurs seçiniz</div>
            ) : (
              <CourseTek course={course} />
            )}
          </div>
        )}
        {showPage === 3 && (
          <div className="w-full bg-yellow-50">
            <CourseContent courseUniqId={course.uniqId} />
          </div>
        )}
        {showPage === 4 && (
          <div className="w-full bg-yellow-50">
            <CourseRules course={course} />
          </div>
        )}
        {showPage === 5 && (
          <div className="w-full bg-yellow-50">
            <CourseStudents courseId={course.id} />
          </div>
        )}
        {showPage === 6 && (
          <div className="w-full bg-yellow-50">
            <CourseAdd
              setState={setShowPage1}
              setCourse={setCourse}
              course={course}
            />
          </div>
        )}
        {showPage === 7 && (
          <div className="w-full bg-yellow-50">
            <CoursePublish setState={setShowPage1} course={course} />
          </div>
        )}
        {showPage === 8 && course.mentorCourse != true ? (
          <div className="w-full bg-yellow-50">
            <MentorCourse mycourse={course} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TeacherCourses;
