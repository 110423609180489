import React, { useState, useEffect } from "react";
import ChunkAdd from "./ChunkAdd";
import ChunkTek from "./ChunkTek";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Modal from "../UI/Modal";
import { useHttp } from "../../hooks/useHttp";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const Chunks = ({ sectionUniqId }) => {
  const [newItemShow, setNewItemShow] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [chunk, setChunk] = useState(null);

  const {
    data: dataChunks,
    error: errorChunks,
    loading: loadingChunks,
    sendRequest: sendRequestChunks,
  } = useHttp();

  useEffect(() => {
    getSectionChunks();
  }, [newItemShow]);

  const getSectionChunks = async () => {
    sendRequestChunks(`/chunks/${sectionUniqId}/section`);
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/chunks/${sectionId}/section`
    // );
    // const data = await response.json();
    // setChunks(data);
    // console.log(`chunks with sectionID ${sectionId}`);
    // console.log(data);
  };
  useEffect(() => {
    if (dataChunks) {
      setChunks(dataChunks);
    }
  }, [dataChunks]);

  useEffect(() => {
    if (chunk !== null) {
      setNewItemShow(true);
    }
  }, [chunk]);

  return (
    <>
      <div className="flex flex-row gap-1">
        <div className="border  border-l-red-400 "></div>
        <div className="flex">
          <div className=" flex flex-col ">
            <div className="flex justify-center">
              <div className="text-3xl flex flex-row gap-4">
                Konular{" "}
                <span
                  className="flex flex-col justify-end"
                  onClick={() => {
                    setChunk(null);
                    setNewItemShow(true);
                  }}
                >
                  <PlusCircleIcon className="text-red-400 h-6 w-6" />
                </span>
              </div>
            </div>
            <div>
              {chunks.length > 0 && !loadingChunks ? (
                chunks.map((item) => (
                  <ChunkTek item={item} setChunk={setChunk} />
                ))
              ) : chunks.length == 0 && !loadingChunks ? (
                <div className="m-4 p-4">
                  Lütfen artıya basarak konu ekleyiniz.
                </div>
              ): ( <LocalSpinnerBar/>)}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row justify-center ">
            </div>
            {newItemShow && (
              <Modal
                onClose={() => {
                  setNewItemShow(false);
                  setChunk(null);
                }}
                title={
                  chunk != null
                    ? `${chunk.orderNo}- Konu Güncelle`
                    : `Konu Ekleme`
                }
              >
                <ChunkAdd
                  setNewItemShow={setNewItemShow}
                  sectionUniqId={sectionUniqId}
                  chunk={chunk}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chunks;
