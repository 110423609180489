import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../store/auth-context";
import CourseDetail from "../components/Course/CourseDetail";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import CourseNotFound from "../components/Course/CourseNotFound";
import { useHttp } from "./../hooks/useHttp"


const CourseDetailPage = () => {
	const authCtx = useContext(AuthContext);
	const [course, setCourse] = useState(null);
	const [mine, setMine] = useState(false);

	const { data: dataCourse, loading: loadingCourse, error: errorCourse, sendRequest: SendRequestCourse } = useHttp();
	const { data: dataUserCourse, loading: loadingUserCourse, error: errorUserCourse, sendRequest: SendRequestUserCourse } = useHttp();

	const params = useParams();
	const { uid } = params;

	useEffect(() => {
		getCourse();
		if(authCtx.token){
			getMineCourse();
		}
	}, []);

	const getCourse = async () => {
		await SendRequestCourse(`/courses/${uid}`);
	}

	const getMineCourse = async () => {
		await SendRequestUserCourse("/users/single");
	}
	useEffect(() => {
		if (dataCourse) {
			setCourse(dataCourse);
		}
		if (dataCourse && dataUserCourse) {
			let benimmi = dataUserCourse.courses.find((item) => item.course.uniqId == dataCourse.uniqId);
			if (benimmi !== undefined) {
				setMine(true);
			} else {
				setMine(false);
			}
		}

	}, [dataCourse, dataUserCourse])


	// const getCourse = async () => {
	// 	setIsLoading(true);
	// 	try {
	// 		const responseCourse = await fetch(`${process.env.REACT_APP_API_URL}/courses/${id}`);
	// 		if (responseCourse.ok) {
	// 			const dataCourse = await responseCourse.json();
	// 			console.log(`this course`);
	// 			console.log(dataCourse);
	// 			setCourse(dataCourse);

	// 			try {
	// 				const responseUserCourses = await fetch(`${process.env.REACT_APP_API_URL}/users/single`, {
	// 					method: "GET",
	// 					headers: {
	// 						Authorization: `${authCtx.token}`,
	// 						"Content-Type": "application/json",
	// 					},
	// 				});
	// 				if (responseUserCourses.ok) {
	// 					const dataMyCourses = await responseUserCourses.json();
	// 					if (dataMyCourses !== null) {
	// 						console.log(`my courses`);
	// 						console.log(dataMyCourses);
	// 						let benimmi = dataMyCourses.courses.find((item) => item.course.id == dataCourse.id);
	// 						console.log(`benimmi`);
	// 						console.log(benimmi);
	// 						if (benimmi !== undefined) {
	// 							setMine(true);
	// 						} else {
	// 							setMine(false);
	// 						}
	// 					}
	// 				} else {
	// 					throw new Error(`Hata: ${responseUserCourses.status} - ${responseUserCourses.statusText}`);
	// 				}
	// 			} catch (error) {
	// 				console.error("Bir hata oluştu:", error.message);
	// 			}
	// 		} else {
	// 			throw new Error(`Hata: ${responseCourse.status} - ${responseCourse.statusText}`);
	// 		}
	// 	} catch (error) {
	// 		console.error("Bir hata oluştu:", error.message);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}

	// };

	return (
		<>
			{course !== null && loadingCourse == false ? (
				<CourseDetail course={course} mine={mine} />
			) : course == null && loadingCourse == false ? (
				<div className="bg-red-200">
					<CourseNotFound />
				</div>
			) : (<div>
				<LoadingSpinner />
			</div>)}
		</>
	);
};

export default CourseDetailPage;
