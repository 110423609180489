import { useState, useContext, useCallback, useSyncExternalStore } from "react";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useHttp = () => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const sendRequest = useCallback(async (url, method = "GET", body = null, headers = {}) => {
		setLoading(true);
		setError(null);
		try {
			const requestOptions = {
				method,
				headers: { "Content-Type": "application/json", Authorization: ` ${authCtx.token}`, ...headers },
				body: body ? JSON.stringify(body) : null,
			};
			const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions);
			// console.log("response htptp");
			// console.log(response);
			if (!response.ok) {
				// console.log("ok degil");
				if (response.status === 401) {
					console.log("status 401");
					// localStorage.removeItem("token");
					authCtx.logout();
					navigate("/");
					console.log("navigated")
				} else {
					const errorData = await response.json();
					setError(errorData);
					// toast.error(errorData || "Hatalı işlem.");
					// toast.error(`${url}-- HATA --- ${errorData}`);
					toast.error(`Hatalı işlem.`);
				}
			} else {
				// console.log(`ok`);
				const responseData = await response.json();
				// console.log("responseData");
				// console.log(responseData);
				if(method !== "GET"){
					toast.success(`Başarılı işlem.`);
				}

				setData(responseData);
			}
		} catch (e) {
			setError(e);
		} finally {
			setLoading(false);
		}
	}, []);

	return { data, error, loading, sendRequest };
};
