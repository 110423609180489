import React, { useEffect, useState } from "react";
import Video from "./Video";
import Kelime from "./Kelime";
import Test2 from "./Test2";
import Reading2 from "./Reading2";
import Summary2 from "./Summary2";
import { useParams } from "react-router-dom";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ClockIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useHttp } from "../../hooks/useHttp";

const CourseStart = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [showFinish, setShowFinish] = useState(false);
	const [showFinishSaved, setShowFinishSaved] = useState(false);
	const [allData, setAllData] = useState({});
	const [chunkAllItems, setChunkAllItems] = useState([]);
	const params = useParams();
	const { uid } = params;
	const [secondsRemaining, setSecondsRemaining] = useState(60 * 60);
	const [isVisible, setIsVisible] = useState(true); // Sayaç görünürlüğünü kontrol eden state
	const navigate = useNavigate();

	const { data: dataStart, error: errorStart, loading: loadingStart, sendRequest: sendRequestStart } = useHttp();
	const {
		data: dataProgress,
		error: errorProgress,
		loading: loadingProgress,
		sendRequest: sendRequestProgress,
	} = useHttp();

	useEffect(() => {
		getLastChunkItems();
	}, []);

	const getLastChunkItems = async () => {
		sendRequestStart(`/userCourseProgresses/${uid}/start`);
	};

	useEffect(() => {
		if (dataStart) {
			setAllData(dataStart);
			setChunkAllItems(
				dataStart.sections[0].chunks[0].chunkItems.sort((a, b) => a.orderNo - b.orderNo)
			);
		}
	}, [dataStart]);

	useEffect(() => {
		if (secondsRemaining > 0) {
			const intervalId = setInterval(() => {
				setSecondsRemaining((prevSeconds) => prevSeconds - 1);
			}, 1000);

			return () => clearInterval(intervalId); // Temizlik için interval'i temizle
		}
	}, [secondsRemaining]);

	const minutes = Math.floor(secondsRemaining / 60);
	const seconds = secondsRemaining % 60;

	const handlePlusSayfa = () => {
		if (currentPage + 1 < chunkAllItems.length) {
			setCurrentPage((prevPage) => prevPage + 1);
		} else {
			setShowFinish(true);
			setCurrentPage(chunkAllItems.length);
		}
	};
	const handleMinusSayfa = () => {
		if (currentPage !== 0) {
			setCurrentPage((prevPage) => prevPage - 1);
			setShowFinish(false);
		}
	};

	const handleSaveChunk = () => {
		sendRequestProgress(`/userCourseProgresses/${allData.uniqId}`, "PUT");
		
	};

useEffect(()=>{
	if(dataProgress){
		setShowFinishSaved(true);
	}
},[dataProgress,sendRequestProgress])

	return (
		<>
			{chunkAllItems.length !== 0 && !loadingStart ? (
				<div>
					{/* Header Bölümü */}
					<div>
						<div>
							<div className='flex flex-row justify-between'>
								<div className='flex-1 flex pt-2 justify-between w-full'>
									<div
										onClick={() => navigate("/profile")}
										className='font-bold text-sm sm:text-lg p-2 border rounded-3xl bg-red-600 text-black self-center cursor-pointer'
									>
										Exit Work
									</div>
									<div className='flex flex-col justify-center gap-1'>
										<div className='flex gap-1'>
											<ClockIcon className='w-6 h-6' onClick={() => setIsVisible(!isVisible)} />
											<div className=''>
												{isVisible && (
													<div>
														<h1>
															{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
														</h1>
														{secondsRemaining === 0 && <p>Süre doldu!</p>}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className='flex-1 flex justify-end pt-2'>
									<div>
										<div className='flex flex-row justify-between items-center'>
											<button onClick={handleMinusSayfa}>
												<ArrowLongLeftIcon className='w-10 h-10 border rounded-3xl bg-green-500 cursor-pointer' />
											</button>

											<div className='flex self-center text-2xl '>
												{currentPage + 1}/{chunkAllItems.length + 1}
											</div>

											<button onClick={handlePlusSayfa}>
												<ArrowLongRightIcon className='w-10 h-10 border rounded-3xl bg-green-500 cursor-pointer' />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Alt Bölüm */}
					{showFinish && showFinishSaved && !loadingProgress ? (
						<div className='flex flex-row justify-center'>
							<div className='flex flex-col justify-center w-96 h-32 p-3 m-2 bg-red-300'>
								<div className='m-4'>Tebrikler Tamamladınız.</div>
								<button className='p-3 bg-green-300' onClick={() => navigate("/profile")}>
									Profil sayfasına git
								</button>
							</div>
						</div>
					) : showFinish && !loadingProgress ? (
						<div className='flex flex-row justify-center'>
							<div className='flex flex-col justify-center w-96 h-32 p-3 m-2 bg-red-300'>
								<div className='m-4'>Kursu tamladınız. Lütfen kayıt edin.</div>
								<button className='p-3 bg-green-300' onClick={handleSaveChunk}>
									Kaydet
								</button>
							</div>
						</div>
					) : showFinish && loadingProgress ? (
						<div>
							<LoadingSpinner />
						</div>
					) : (
						<div className=''>
							{chunkAllItems[currentPage].type == "video" && <Video item={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "reading" && <Reading2 chunkItem={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "word" && <Kelime chunkItem={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "summary" && <Summary2 chunkItem={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "test" && <Test2 chunkItem={chunkAllItems[currentPage]} />}
						</div>
					)}
				</div>
			) : chunkAllItems.length == 0 && !loadingStart ? (
				<div>Kursun bu bölümü daha yüklenmemiştir.</div>
			) : (
				<div>
					<LoadingSpinner />
				</div>
			)}
		</>
	);
};

export default CourseStart;
