import React, { useEffect, useState } from "react";
import { Cog6ToothIcon, UserIcon, CogIcon, UserCircleIcon } from "@heroicons/react/24/solid";

import ProfileCourses from "./ProfileCourses";
import { Routes, Route, useNavigate, NavLink } from "react-router-dom";
import LoadingSpinner from "../UI/LoadingSpinner";
import MentorCourses from "./MentorCourses";

const Profile = ({ user }) => {
	const [userMissedPoint, setUserMissedPoint] = useState(0);

	useEffect(() => {
		let totalCount = 0;
		if (user != null) {
			if (user.name == null) {
				totalCount += 1;
			}
			if (user.surname == null) {
				totalCount += 1;
			}
			if (user.birthday == null) {
				totalCount += 1;
			}
			if (user.homeTown == null) {
				totalCount += 1;
			}
			if (user.phone == null) {
				totalCount += 1;
			}
			if (user.photoUrl == null) {
				totalCount += 1;
			}
			setUserMissedPoint(totalCount);
		}
	}, [user]);

	function calculateAge(birthdate) {
		if (birthdate) {
			const currentDate = new Date();
			const birthDate = new Date(birthdate);

			let age = currentDate.getFullYear() - birthDate.getFullYear();

			if (
				currentDate.getMonth() < birthDate.getMonth() ||
				(currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
			) {
				age--;
			}

			return age;
		} else {
			return null;
		}
	}

	return (
		<div>
			<div className='bg-gradient-to-r from-red-500 to-red-100 p-4 rounded-xl shadow-lg flex flex-col sm:flex-row items-center sm:justify-between h-auto sm:h-44 border border-gray-400 w-full'>
				<div className='flex flex-col sm:flex-row items-center sm:space-y-0 sm:space-x-6 w-full sm:w-auto'>
					{user.photoUrl ? (
						<img src={user.photoUrl} alt='Fetched' className='w-24 h-24 sm:w-32 sm:h-32 rounded-full m-3' />
					) : (
						<div className='bg-gray-200 sm:w-auto sm:h-full text-3xl rounded-full p-5 m-4'>
							<UserCircleIcon className='h-24 w-24 text-gray-800' />
						</div>
					)}

					<div className='flex flex-col justify-center text-center sm:text-left'>
						<div className='flex fex-row gap-10'>
							<h2 className='text-lg sm:text-xl font-bold text-gray-800'>
								{user.name} {user.surname}
							</h2>
						</div>
						<div className='flex flex-col sm:flex-row sm:space-x-10 mt-2 space-y-2 sm:space-y-0'>
							<p className='text-sm sm:text-base text-gray-700'>
								Yaş:{" "}
								<span className='text-gray-900 font-semibold'>
									{user.birthday ? calculateAge(user.birthday) : `Girilmemiş`}
								</span>
							</p>
							<p className='text-sm sm:text-base text-gray-700'>
								Meslek:{" "}
								<span className='text-gray-900 font-semibold'>
									{user.occupation ? user.occupation : <span>Girilmemiş</span>}
								</span>
							</p>
							<p className='text-sm sm:text-base text-gray-700'>
								Memleket:{" "}
								<span className='text-gray-900 font-semibold'>
									{user.homeTown ? user.homeTown : <span>Girilmemiş</span>}
								</span>
							</p>
						</div>
					</div>
				</div>
				<div className='flex flex-col gap-3'>
					<h2 className='text-lg sm:text-xl font-bold text-gray-800'>{user.email}</h2>
					<div className='flex flex-row justify-end'>
						<button className='relative mt-4 sm:mt-0 sm:mr-10 w-fit'>
							<NavLink to='/profileSettings'>
								<CogIcon className='h-6 w-6 sm:h-8 sm:w-8 text-gray-700 hover:text-gray-900' />
								{userMissedPoint !== 0 && (
									<span className='flex absolute h-3 w-3 top-0 right-0 transform translate-x-1/2 -translate-y-1/2'>
										<span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75'>
											{userMissedPoint}
										</span>
										<span className='relative inline-flex rounded-full h-3 w-3 bg-red-600'></span>
									</span>
								)}
							</NavLink>
						</button>
					</div>
				</div>
			</div>
			<br />

			<hr />
			<br />

			{/* <h1 className="text-5xl font-medium">Kurslar</h1> */}
			<br />
			{user.courses.length !== 0 ? (
				user.courses.map((item) => (
					<>{!item.course.mentorCourse ? <ProfileCourses item={item} /> : <MentorCourses item={item} />}</>
				))
			) : (
				<div>Su anda bir kursa kayitli degilsiniz. Lutfen bir kursa kaydolunuz ve burada kurslariniz gozukecektir.</div>
			)}
		</div>
	);
};
export default Profile;
