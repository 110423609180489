import React, { useState } from "react";
import ChunkItems from "./ChunkItems";
import { PencilSquareIcon } from "@heroicons/react/24/solid";

const ChunkTek = ({ item, setChunk }) => {
	const { name, description, uniqId, orderNo } = item;
	const [showItems, setShowItems] = useState(false);

	return (
		<>
			<div className='flex flex-row justify-start'>
				<div className='flex flex-row'>
					<div className='m-2 flex flex-col justify-start mt-10'>
						<span
							onClick={() => {
								setChunk(item);
							}}
							className='cursor-pointer bg-white  '
						>
							<PencilSquareIcon className='text-red-300 w-6 h-6' />
						</span>
					</div>
				</div>
				<div>
					<div
						className='m-1 border border-gray-300 bg-blue-300 rounded-lg flex flex-row p-2'
						onClick={() => {
							setShowItems(!showItems);
						}}
					>
						<div className='w-72'>
							<div>{orderNo}. Konu</div>
							<div>{name}</div>
							{/* <div>Descrition {description}</div>
					<div>sectionId: {sectionId}</div> */}
						</div>
					</div>
					{showItems && <ChunkItems chunkUniqId={uniqId} />}
				</div>
			</div>
		</>
	);
};

export default ChunkTek;
