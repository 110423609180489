import React, { useEffect, useContext } from "react";
import {useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";

const ConfirmAccountHeader = () => {
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);

	const { data, error, loading, sendRequest } = useHttp();

	const handleConfirmAccount = () => {
		sendRequest("/mail");
		authCtx.user.active=true;
	};

	return (
		<>
			{authCtx.user.active == false ? (
				<div>
					<nav className='md:flex flex justify-center p-2  bg-yellow-400 text-white font-bold h-30'>
						{data != null ? (
							<div>Hesabınıza mail gönderildi</div>
						) : (
							<>
								<div className='flex flex-col justify-center p-1'>Hesabınızın size ait olduğunu doğrulayın.</div>
								<button onClick={handleConfirmAccount} className='p-2 m-1 bg-green-400 rounded-lg '>
									Onaylama Kodu gönder
								</button>
							</>
						)}
					</nav>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default ConfirmAccountHeader;
