import React, { useState, useEffect } from "react";
import EksCourse from "../components/EKS/EksCourse";
import { useHttp } from "../hooks/useHttp";

function EksPage() {
  const [courses, setCourses] = useState([]);

  const {
    data: dataCourses,
    error: errorCourses,
    loading: loadingCourses,
    sendRequest: sendRequestCourses,
  } = useHttp();

  const getCourse = async () => {
    sendRequestCourses(`/courses/active`);
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/courses/active`
    // );
    // const data = await response.json();
    // console.log("courses");
    // console.log(data);
    // if (data.length) {
    //   setCourses(data);
    // }
  };

  useEffect(() => {
    if (dataCourses) {
      setCourses(dataCourses);
    }
  }, [sendRequestCourses, dataCourses]);

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div className="">
      <div className="bg-red-500 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl m-4">
        <div className="flex flex-col justify-center">Kurslar</div>
      </div>
      <div className="">
        {courses.length !== 0 ? (
          courses.map((course) => (
            <EksCourse
              key={course.uniqId}
              uniqId={course.uniqId}
              name={course.name}
              description={course.description}
            />
          ))
        ) : (
          <div>Nothing</div>
        )}
      </div>
    </div>
  );
}

export default EksPage;
