import React, { useState, useContext, useMemo, useEffect } from "react";
import SortableTable from "../UI/SortableTable/SortableTable";
import { useHttp } from "../../hooks/useHttp";
import Modal from "../UI/Modal";
import Dropdownlist from "../UI/Dropdownlist";
import { Link } from "react-router-dom";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teacherUniqId, setTeacherUniqId] = useState(null);
  const [formSend, setFormSend] = useState(false);
  const [opneEditModal, setOpenEditModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    uniqId: 0,
    active: false,
    managerConfirmed: false,
  });
  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const {
    data: dataTeacherCourses,
    error: errorTeacherCourses,
    loading: loadingTeacherCourses,
    sendRequest: sendRequestTeacherCourses,
  } = useHttp();

  const {
    data: dataCourses,
    error: errorCourses,
    loading: loadingCourses,
    sendRequest: sendRequestCourses,
  } = useHttp();
  const {
    data: dataTeachers,
    error: errorTeachers,
    loading: loadingTeachers,
    sendRequest: sendRequestTeachers,
  } = useHttp();

  const {
    data: dataCourseUpdate,
    error: errorCourseUpdate,
    loading: loadingCourseUpdate,
    sendRequest: sendRequestCourseUpdate,
  } = useHttp();

  const columns = useMemo(
    () => [
      {
        Header: "Ad",
        accessor: "name",
        filter: "fuzzyText",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row">
            <Link
              to={`/course/${row.row.original.uniqId}`}
              className="cursor-pointer underline text-blue-500 p-2 m-1 rounded-lg"
            >
              {row.row.original.name}
            </Link>
          </div>
        ),
      },
      {
        Header: "Açıklama",
        accessor: "description",
        disableFilters: true,
      },
      // {
      //   Header: "Uniq Id",
      //   accessor: "uniqId",
      //   disableFilters: true,
      // },
      {
        Header: "Fiyat",
        accessor: "price",
        disableFilters: true,
      },
      {
        Header: "Active",
        accessor: "active",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.active != "undefined"
                  ? row.row.original.active
                  : false
              }
            />
          </div>
        ),
      },
      {
        Header: "Admin Onay",
        accessor: "managerConfirmed",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.managerConfirmed != "undefined"
                  ? row.row.original.managerConfirmed
                  : false
              }
            />
          </div>
        ),
      },
      {
        Header: "Edit",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => (
          <div>
            <button
              type="button"
              className="button-warn"
              onClick={(e) => onCustomerEdit(row.row.original)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const onCustomerEdit = (e) => {
    setFormData({
      name: e.name,
      description: e.description,
      price: e.price,
      active: e.active,
      managerConfirmed: e.managerConfirmed,
      uniqId: e.uniqId,
    });
    setOpenEditModal(true);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    await sendRequestCourseUpdate(
      `/courses/${formData.uniqId}`,
      "PUT",
      formData
    );
    setFormSend(true);
    setOpenEditModal(false);
    getCourses();
  };

  const hadleModal = () => {
    setOpenEditModal(false);
  };

  useEffect(() => {
    getCourses();
    getTeachers();
  }, []);

  useEffect(() => {
    if (teacherUniqId !== null) {
      getTeacherCourses();
    }
  }, [teacherUniqId]);

  const getTeacherCourses = async () => {
    sendRequestTeacherCourses(`/userCourses/${teacherUniqId}/user`);
  };

  useEffect(() => {
    if (dataTeacherCourses) {
      console.log("dataTeacherCourses");
      console.log(dataTeacherCourses);
      setCourses(dataTeacherCourses);
    }
  }, [sendRequestTeacherCourses, dataTeacherCourses]);

  const getCourses = async () => {
    sendRequestCourses(`/courses/foradmin`);
  };

  useEffect(() => {
    if (dataCourses) {
      console.log("courses")
      console.log(courses)
      setCourses(dataCourses);
    }
  }, [sendRequestCourses, dataCourses]);

  const getTeachers = async () => {
    sendRequestTeachers(`/roles/Teacher/roleName`);
  };

  useEffect(() => {
    if (dataTeachers) {
      setTeachers(dataTeachers);
    }
  }, [dataTeachers]);

  const handleSelectTeacher = (e) => {
    if (e.target.value !== null) {
      console.log(e.target.value);
      setTeacherUniqId(e.target.value);
    } else {
      setTeacherUniqId(null);
    }
  };

  return (
    <div className="h-auto">
      {opneEditModal && (
        <Modal onClose={hadleModal} title="Kurs Düzenleme">
          <div className="p-4 max-w-md mx-auto">
            <form onSubmit={handleSubmit1} className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adı
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Açıklama
                </label>
                <input
                  type="text"
                  name="description"
                  id="description"
                  value={formData.description}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fiyat
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  value={formData.price}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div className=" flex gap-3">
                <div className="mb-4 border p-1">
                  <label htmlFor="active" className="flex items-center">
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      checked={formData.active}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Kurs Aktif
                    </span>
                  </label>
                </div>
                <div className="mb-4 border p-1">
                  <label
                    htmlFor="managerConfirmed"
                    className="flex items-center"
                  >
                    <input
                      type="checkbox"
                      id="managerConfirmed"
                      name="managerConfirmed"
                      checked={formData.managerConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Admin Onayı
                    </span>
                  </label>
                </div>
              </div>

              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Düzenle
              </button>
            </form>
          </div>
        </Modal>
      )}
      <div className="font-semibold text-2xl m-2">Kurslar Tablosu</div>
      {teachers.length != 0 && !loadingTeachers ? (
        <>
          <Dropdownlist
            content={teachers.map((item) => (
              <option value={item.uniqId}>
                {item.name} - {item.email}
              </option>
            ))}
            title="Öğretmen seçiniz."
            handleSelected={handleSelectTeacher}
            selectedItem={teacherUniqId}
          />
        </>
      ) : teachers.length == 0 && !loadingTeachers ? (
        <div>Öğretmen Bulunamadı.</div>
      ) : (
        <div>
          <LocalSpinnerBar />
        </div>
      )}

      {courses.length != 0 && !loadingTeacherCourses && !loadingCourses ? (
        <>
          <SortableTable columns={columns} data={courses} search={false} />
        </>
      ) : courses.length == 0 && !loadingTeacherCourses && !loadingCourses ? (
        <div>Kurslar bulunamadı.</div>
      ) : (
        <div>
          <LocalSpinnerBar />
        </div>
      )}
    </div>
  );
};

export default Courses;
