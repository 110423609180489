import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const LocalSpinnerBar = () => {
    return (
        <div className="relative w-full h-full">
            <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
                <ArrowPathIcon className="w-12 h-12 text-green-500 animate-spin" />
            </div>
        </div>
    );
};

export default LocalSpinnerBar;