import React, { useState, useContext, useMemo, useEffect } from "react";
import SortableTable from "../UI/SortableTable/SortableTable";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";
import Modal from "../UI/Modal";
import Dropdownlist from "../UI/Dropdownlist";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import LocalSpinnerBar from "../UI/LocalSpinnerBar"

const Students = () => {
  const authCtx = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseUniqId, setCourseUniqId] = useState(null);
  const [formSend, setFormSend] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddStudentModal, setOpenAddStudentModal] = useState(false);

  const [formData, setFormData] = useState({
    uniqId: "",
    name: "",
    surname: "",
    email: "",
    birthday: "",
    homeTown: "",
    occupation: "",
    emailConfirmed: "",
    phoneConfirmed: "",
    phone: "",
    role: "Student",
    active: false,
    managerActive: false,
  });
  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const { data: dataCourses, error: errorCourses, loading: loadingCourses, sendRequest: sendRequestCourses } = useHttp();
  const { data: dataCourseStudents, error: errorCourseStudents, loading: loadingCourseStudents, sendRequest: sendRequestCourseStudents } = useHttp();
  const { data: dataStudents, error: errorStudents, loading: loadingStudents, sendRequest: sendRequestStudents } = useHttp();
  const { data: dataUpdateStudent, error: errorUpdateStudent, loading: loadingUpdateStudent, sendRequest: sendRequestUpdateStudent } = useHttp();
  const { data: dataAddStudent, error: errorAddStudent, loading: loadingAddStudent, sendRequest: sendRequestAddStudent } = useHttp();

  const columns = useMemo(
    () => [
      {
        Header: "Ad",
        accessor: "name",
        filter: "fuzzyText",
        disableFilters: true,
      },
      {
        Header: "Soyad",
        accessor: "surname",
        disableFilters: true,
      },
      {
        Header: "Doğum günü",
        accessor: "birthday",
        disableFilters: true,
      },
      {
        Header: "Memleket",
        accessor: "homeTown",
        disableFilters: true,
      },
      {
        Header: "Meslek",
        accessor: "occupation",
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.emailConfirmed != "undefined"
                  ? row.row.original.emailConfirmed
                  : false
              }
            />
            <div>{row.row.original.email}</div>
          </div>
        ),
      },
      {
        Header: "Telefon ",
        accessor: "phone",
        disableFilters: true,
        Cell: (row) =>
          row.row.original.phone ? (
            <div className="flex flex-row gap-3 text-center">
              <input
                type="checkbox"
                checked={
                  typeof row.row.original.phoneConfirmed != "undefined"
                    ? row.row.original.phoneConfirmed
                    : false
                }
              />
              <div>{row.row.original.phone}</div>
            </div>
          ) : (
            <div>Veri yok</div>
          ),
      },
      {
        Header: "Active",
        accessor: "active",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.active != "undefined"
                  ? row.row.original.active
                  : false
              }
            />
          </div>
        ),
      },
      {
        Header: "Admin Onay",
        accessor: "managerConfirmed",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.managerConfirmed != "undefined"
                  ? row.row.original.managerConfirmed
                  : false
              }
            />
          </div>
        ),
      },
      {
        Header: "Edit",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => (
          <div>
            <button
              type="button"
              className="button-warn"
              onClick={(e) => onCustomerEdit(row.row.original)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const onCustomerEdit = (e) => {
    setFormData({
      uniqId: e.uniqId,
      name: e.name,
      uniqId: e.uniqId,
      surname: e.surname,
      email: e.email,
      birthday: e.birthday,
      homeTown: e.birthday,
      occupation: e.occupation,
      phone: e.phone,
      emailConfirmed: e.emailConfirmed,
      phoneConfirmed: e.phoneConfirmed,
      role: "Student",
      active: e.active,
      managerConfirmed: e.managerConfirmed,
    });
    setOpenEditModal(true);
  };


  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    await sendRequestUpdateStudent("/admin/users", "PUT", formData);
    getStudents();
  };

  useEffect(() => {
    if (dataUpdateStudent) {
      handleCloseUpdateStudentModal();
    }

  }, [dataUpdateStudent])


  const handleSubmitAddStudent = () => {
    sendRequestAddStudent("/admin/users", "POST", formData);
  };

  useEffect(() => {
    if (dataAddStudent) {
      handleCloseAddStudentModal();
    }
  }, [dataAddStudent])

  const handleCloseAddStudentModal = () => {
    setOpenAddStudentModal(false);
    document.body.classList.remove("overflow-hidden");
    getStudents();
  };
  const handleCloseUpdateStudentModal = () => {
    setOpenEditModal(false);
    document.body.classList.remove("overflow-hidden");
    getStudents();
  };


  useEffect(() => {
    getStudents();
    getCourses();
  }, []);

  // Need fix
  useEffect(() => {
    if (courseUniqId == 0 || courseUniqId == null) {
      getStudents();
    } else {
      getCourseStudents();
    }
  }, [courseUniqId]);

  const getCourses = async () => {
    sendRequestCourses(`/courses/foradmin`);
  };

  useEffect(() => {
    if (dataCourses) {
      setCourses(dataCourses);
    }
  }, [dataCourses])


  const getCourseStudents = async () => {
    await sendRequestCourseStudents(`/userCourses/${courseUniqId}/course`);
  };

  useEffect(() => {
    if (dataCourseStudents) {
      console.log("dataCourseStudents")
      console.log(dataCourseStudents)
      setUsers(dataCourseStudents);
    }
  }, [dataCourseStudents])



  const getStudents = async () => {
    await sendRequestStudents("/roles/Student/roleName");
  };

  useEffect(() => {
    if (Array.isArray(dataStudents)) {
      setUsers(dataStudents);
    }
  }, [dataStudents])

  const handleSelectCourse = (e) => {
    console.log("e.target.value")
    console.log(e.target.value)
    if (e.target.value !== null) {
      setCourseUniqId(e.target.value);
    } else {
      setCourseUniqId(null);
    }
  };

  return (
    <div className="h-auto">
      {openEditModal && (
        <Modal onClose={handleCloseAddStudentModal} title="Öğrenci Düzenleme">
          <div className="p-4 max-w-md mx-auto">
            <div className="font-semibold text-3xl m-4"></div>
            {loadingAddStudent ? <LocalSpinnerBar /> :
              <form onSubmit={handleSubmitUpdate} className="space-y-4">
                <div>
                  <input
                    type="text"
                    name="uniqId"
                    id="uniqId"
                    value={formData.uniqId}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adınız
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Soyadınız
                  </label>
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    value={formData.surname}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Adresiniz
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    disabled
                    value={formData.email}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="hasMentie" className="flex items-center">
                    <input
                      type="checkbox"
                      id="emailConfirmed"
                      name="emailConfirmed"
                      checked={formData.emailConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Email Onayı
                    </span>
                  </label>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="birthday"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Birthday:
                  </label>
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="homeTown"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Memleket:
                  </label>
                  <input
                    type="text"
                    id="homeTown"
                    name="homeTown"
                    value={formData.homeTown}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="occupation"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Meslek:
                  </label>
                  <input
                    type="text"
                    id="occupation"
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Phone:
                  </label>
                  <input
                    placeholder="Başında 0 olmadan 5382892872 gibi yazınınız."
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                  />
                </div>
                <div className=" flex gap-3">
                  <div className="mb-4 border p-1">
                    <label htmlFor="phoneConfirmed" className="flex items-center">
                      <input
                        type="checkbox"
                        id="phoneConfirmed"
                        name="phoneConfirmed"
                        checked={formData.phoneConfirmed}
                        onChange={handleChange}
                        className="mr-2 leading-tight"
                      />
                      <span className="text-sm font-medium text-gray-600">
                        Telefon Onayı
                      </span>
                    </label>
                  </div>
                  <div className="mb-4 border p-1">
                    <label htmlFor="active" className="flex items-center">
                      <input
                        type="checkbox"
                        id="active"
                        name="active"
                        checked={formData.active}
                        onChange={handleChange}
                        className="mr-2 leading-tight"
                      />
                      <span className="text-sm font-medium text-gray-600">
                        Kullanıcı Aktif
                      </span>
                    </label>
                  </div>
                  <div className="mb-4 border p-1">
                    <label
                      htmlFor="managerConfirmed"
                      className="flex items-center"
                    >
                      <input
                        type="checkbox"
                        id="managerConfirmed"
                        name="managerConfirmed"
                        checked={formData.managerConfirmed}
                        onChange={handleChange}
                        className="mr-2 leading-tight"
                      />
                      <span className="text-sm font-medium text-gray-600">
                        Admin Onayı
                      </span>
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Düzenle
                </button>
              </form>}
          </div>
        </Modal>
      )}
      {openAddStudentModal && (
        <Modal onClose={handleCloseAddStudentModal} title="Öğrenci ekleme">
          <div className="">
            {loadingUpdateStudent ? <LocalSpinnerBar /> :
              <form onSubmit={handleSubmitAddStudent} className="space-y-4 mb-10">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adınız
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Soyadınız
                  </label>
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    value={formData.surname}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-sm font-medium text-gray-700"
                  >
                    Email Adresiniz
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="emailConfirmed" className="flex items-center">
                    <input
                      type="checkbox"
                      id="emailConfirmed"
                      name="emailConfirmed"
                      checked={formData.emailConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Email Onayı
                    </span>
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    type="text"
                    name="password"
                    id="password"
                    value={formData.password}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="birthday"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Birthday:
                  </label>
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="homeTown"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Memleket:
                  </label>
                  <input
                    type="text"
                    id="homeTown"
                    name="homeTown"
                    value={formData.homeTown}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="occupation"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Meslek:
                  </label>
                  <input
                    type="text"
                    id="occupation"
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Phone:
                  </label>
                  <input
                    placeholder="Başında 0 olmadan 5382892872 gibi yazınınız."
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                  />
                </div>
                <div className=" flex gap-3">
                  <div className="mb-4 border p-1">
                    <label htmlFor="phoneConfirmed" className="flex items-center">
                      <input
                        type="checkbox"
                        id="phoneConfirmed"
                        name="phoneConfirmed"
                        checked={formData.phoneConfirmed}
                        onChange={handleChange}
                        className="mr-2 leading-tight"
                      />
                      <span className="text-sm font-medium text-gray-600">
                        Telefon Onayı
                      </span>
                    </label>
                  </div>
                  <div className="mb-4 border p-1">
                    <label htmlFor="active" className="flex items-center">
                      <input
                        type="checkbox"
                        id="active"
                        name="active"
                        checked={formData.active}
                        onChange={handleChange}
                        className="mr-2 leading-tight"
                      />
                      <span className="text-sm font-medium text-gray-600">
                        Kullanıcı Aktif
                      </span>
                    </label>
                  </div>
                  <div className="mb-4 border p-1">
                    <label
                      htmlFor="managerConfirmed"
                      className="flex items-center"
                    >
                      <input
                        type="checkbox"
                        id="managerConfirmed"
                        name="managerConfirmed"
                        checked={formData.managerConfirmed}
                        onChange={handleChange}
                        className="mr-2 leading-tight"
                      />
                      <span className="text-sm font-medium text-gray-600">
                        Admin Onayı
                      </span>
                    </label>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="w-3/4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Ekle
                  </button>
                </div>
              </form>
            }

          </div>
        </Modal>
      )}
      <div className=" font-semibold text-2xl m-2">Öğrenciler Tablosu</div>
      {courses.length != 0 && !loadingCourses ? (
        <div className="flex justify-between">
          <Dropdownlist
            content={courses.map((item) => (
              <option value={item.uniqId}>{item.name}</option>
            ))}
            title="Kurs seçiniz."
            handleSelected={handleSelectCourse}
            selectedItem={courseUniqId}
          />

          <div
            className="button-green w-30 flex justify-between"
            onClick={() => setOpenAddStudentModal(true)}
          >
            <span className="">Öğrenci</span>
            <PlusCircleIcon />
          </div>
        </div>
      ) : courses.length == 0 && !loadingCourses ? (
        <div>Kayıtlı kurs bulunmadı.</div>
      ) : (<div><LocalSpinnerBar /></div>)}
      {users.length != 0 && !loadingStudents && !loadingCourseStudents ? (
        <>
          <SortableTable columns={columns} data={users} search={false} />
        </>
      ) : users.length == 0 && !loadingStudents && !loadingCourseStudents ? (
        <div>Öğrenci bulunmadı</div>
      ) : (<div><LocalSpinnerBar /></div>)}
    </div>
  );
};

export default Students;
