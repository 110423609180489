import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ClockIcon } from "@heroicons/react/24/solid";
import { useHttp } from "../../hooks/useHttp";

import { Link } from "react-router-dom";

const CourseProgress2 = () => {
	const [course, setCourse] = useState(null);
	const [courseGoals, setGoals] = useState([]);
	const params = useParams();
	const { uid } = params;
	const [openUnits, setOpenUnits] = useState({});
	const [expanded, setExpanded] = useState({
		objectives: false,
		method: false,
		audience: false,
	});

	const { data, error, loading, sendRequest } = useHttp();

	const toggleUnit = (index) => {
		setOpenUnits((prev) => ({ ...prev, [index]: !prev[index] }));
	};

	const toggleExpansion = (section) => {
		setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
	};

	useEffect(() => {
		getLastChunkItems();
	}, []);

	useEffect(() => {
		if (course !== null && course.goals !== null) {
			setGoals(JSON.parse(course.goals));
		} else {
			setGoals([]);
		}
	}, [course]);

	const getLastChunkItems = async () => {
		await sendRequest(`/userCourseProgresses/${uid}`);
	};

	useEffect(() => {
		if (data) {
			setCourse(data);
			setOpenUnits(data.sections);
		}
	}, [data]);

	return (
		<>
			{loading && course == null ? (
				<div>
					<LoadingSpinner />
				</div>
			) : !loading && course == null ? (
				<h1>Kurs bulunamadı</h1>
			) : (
				<>
					<div className='container mx-auto p-4'>
						<div className='text-red-500 text-xl'>Kırmızı rakamları takip ederek inceleyebilirsin.</div>
						{/* birinci Row */}
						<div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4'>
									<div className=' ml-2 sm:ml-10 mb-3 font-semibold text-xl sm:text-2xl md:text-3xl'>
										<span className='text-red-500 text-3xl '>1- </span>
										<span className='text-center ml-2 text-2xl'>İşlenecek konu</span>
									</div>
									{course.sections.map((section, index) => (
										<div key={index} className='p-2 mb-4 w-fit'>
											<div className='pl-2 sm:pl-5'>
												{section.chunks.map((chunk, idx) => (
													<div
														key={idx}
														className='flex flex-col sm:flex-row my-3 border border-gray-200 rounded-lg p-2 min-w-[100px] overflow-hidden'
													>
														<div className='flex flex-col  gap-2 text-xl  sm:text-base'>
															<div className='w-full sm:min-w-[100px] '>
																{section.orderNo}.{chunk.orderNo}. {chunk.name}
															</div>
															<div className='flex flex-row '>
																<span className='flex justify-center items-center sm:min-w-[100px] bg-red-200 px-1 text-sm rounded-xl'>
																	Kazanım
																</span>
																<span className='flex justify-center items-center mx-2'>
																	<ArrowLongRightIcon className='w-4 h-4' />
																</span>
																<span className='w-full sm:min-w-[100px] text-sm flex justify-center items-center'>
																	{chunk.description}
																</span>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									))}
								</div>
							</div>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-red-50 h-full rounded-lg p-1'>
									<div className=' ml-2 sm:ml-10 mb-3 font-semibold text-xl sm:text-2xl md:text-3xl'>
										<span className='text-red-500 text-3xl '>2- </span>
										<span className='text-center ml-2 text-2xl'>Başlat</span>
									</div>
									<div className='flex flex-col items-center'>
										<span>Ünite: {course.sections[0].orderNo}</span>
										<span>Konu: {course.sections[0].chunks[0].orderNo}</span>{" "}
									</div>
									<div className='flex flex-row justify-center '>
										<button className='bg-blue-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 w-fit m-3'>
											<Link className='btn btn-primary' to={`/course-start/${uid}`}>
												Bilgi Kazanım Başlat
											</Link>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default CourseProgress2;
