import React from "react";
import CoursePage from "../../pages/CoursePage";
import LoggedInUnpayed from "./LoggedInUnpayed";
import LoggedInPayed from "./LoggedInPayed";
import MainDesc from "./MainDesc";

const Main = () => {
  const data = [
    {
      title: "Zincir Okul",
      description: "Bir online güncelleme alan offline eğitim yeridir.",
      items: [
        // "Yazılım Mühendisi Cem Gökçe tarafından yapılmıştır."
      ],
      backGroundColor: "bg-red-500",
    },
    {
      title: "Eğitim",
      description: "Satın aldığınız eğitim öğrenmek amaçlıdır.",
      items: [
        // "Sınava hazırlansak bile amacımız öğrenmek üzerinedir.",
        // "Hayat boyu kullanmak için öğretiyoruz.",
        // "Aldığınız eğitimin bizim tamamlanması halinde amacına ulaşmış olması gerekir.",
        // "Günümüzde birçok online eğitim satılmaktadır amaçları belirsizdir.",
      ],
      backGroundColor: "bg-red-500",
    },
    {
      title: "Paydaşlar",
      description: "Öğretmen, Eğitim ve Öğrenci",
      items: [
        // "Öğretmenlerimiz içerikleri hazırlar ve güncel tutar.",
        // "Öğrencilerimiz öğrenmeye odaklanır ve kurslarını bitirir.",
        // "Kursu bitiren öğrenciler mentor öğrenci olur yeni öğrencilerimize destek olur ve tekrar etme fırsatı bularak kalıcı öğrenme gerçekleşir.",
      ],

      backGroundColor: "bg-red-400",
    },
    {
      title: "İlkemiz",
      description: "Öğrenmeye odaklan.",
      items: [
        // "Kaynak aramak yerine öğrenmeye odaklanılması ilkemizdir.",
        // "Öğrenci olarak görevin kaynak aramak değil.",
        // "Zincir okul olarak biz bu görevi öğretmenlerimize veriyoruz ve kaynaklarını güncel tutarak öğrencilerimizin derslerine odaklanmasını sağlıyoruz.",
      ],
      backGroundColor: "bg-red-400",
    },

    // Diğer kutuların verileri...
  ];

  const Box = ({ title, description, items, backGroundColor }) => (
    <>
      <div
        className={`flex flex-col justify-start rounded h-auto min-h-96  text-white md:min-w-[200px] ${
          backGroundColor ? backGroundColor : ""
        }`}
      >
        <div className="flex flex-col justify-center mx-5">
          <div className="text-2xl">{title}</div>
          <div className="text-xl mb-2">{description}</div>
          <div className=" pl-10">
            <ul className="list-disc tex-small">
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="flex md:flex-row flex-col ">
        
        <div className="flex-1 container mx-auto p-4">
          <div className="bg-red-300 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl my-2 mb-10">
            <div className="flex flex-col justify-center">Tanıtım</div>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
            {data.map((item, index) => (
              <Box key={index} {...item} />
            ))}
          </div>
        </div>
        {/* <MainDesc /> */}
		<div className="flex-1 flex-row justify-center my-4">
          <CoursePage />
        </div>
      </div>
    </>
  );
};

export default Main;
