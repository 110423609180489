import React, { useState, useRef, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";
import { Link, useNavigate, NavLink } from "react-router-dom";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const Payment = () => {
  const authCtx = useContext(AuthContext);
  const [userBaskets, setUserBaskets] = useState([]);
  const {
    data: dataBasket,
    error: errorBasket,
    loading: loadingBasket,
    sendRequest: sendRequestBasket,
  } = useHttp();
  const {
    data: dataPayment,
    error: errorPayment,
    loading: loadingPayment,
    sendRequest: sendRequestPayment,
  } = useHttp();

  const navigate = useNavigate();

  useEffect(() => {
    getUserBasket();
  }, []);

  const getUserBasket = async () => {
    // const data2 = { paid:true };
    if (authCtx.token !== null) {
      sendRequestBasket("/userCoursePayments");
    }
  };

  useEffect(() => {
    if (dataBasket != null && userBaskets.length == 0) {
      setUserBaskets(dataBasket);
    }
  }, [dataBasket]);

  const handlePayment = async (uniqId) => {
    await sendRequestPayment(`/userCoursePayments/${uniqId}`, "PUT");
  };
  useEffect(() => {
    if (dataPayment) {
      navigate("/profile");
    }
  }, [dataPayment]);

  const BasketItem = ({ item, onNavigate, onPayment }) => {
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center p-4 border border-gray-300 rounded-lg shadow-md bg-white hover:bg-gray-100 transition-all">
        <div className="flex-1 text-lg sm:text-xl font-semibold text-center sm:text-left">
          {item.course.name}
        </div>
        <div className="flex-1 text-lg sm:text-xl font-semibold text-center sm:text-left">
          {item.course.price} TL
        </div>
        <div className="flex-1 text-sm sm:text-base text-gray-500 text-center sm:text-left">
          {item.course.createdAt &&
            new Date(item.course.createdAt).toLocaleDateString()}
        </div>
        <div className="flex flex-col sm:flex-row items-center gap-3">
          {item.paid ? (
            <>
              <span className="text-green-600 font-medium">Ödendi</span>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all"
                onClick={onNavigate}
              >
                Kursa Git
              </button>
            </>
          ) : (
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-all"
              onClick={() => onPayment(item.course.uniqId)}
            >
              Öde
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="max-w-4xl mx-auto py-8 px-4 ">
        <div className="flex flex-row">
          <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">
            Sepet
          </h1>
        </div>

        {userBaskets.length != 0 && !loadingBasket ? (
          <div className="flex flex-col gap-4">
            {userBaskets.map((item) => (
              <BasketItem
                key={item.course.uniqId}
                item={item}
                onNavigate={() => navigate("/profile")}
                onPayment={handlePayment}
              />
            ))}
          </div>
        ) : userBaskets.length === 0 && !loadingBasket ? (
          <div className="text-center text-gray-500 text-lg">
            Sepetiniz boş. Eklemek için kurslara göz atın!
          </div>
        ) : (
          <div>
            <LocalSpinnerBar />
          </div>
        )}
      </div>
      <div class="absolute bottom-4 right-4 flex space-x-2 ">
        <img src="/visa-logo.png" alt="Visa" class="h-16" />
        <img src="/master-card-logo.png" alt="Mastercard" class="h-16" />
        <img src="/iyzico-logo.png" alt="İyzico" class="h-16" />
      </div>
    </>
  );
};

export default Payment;
