import React, { Fragment, useState, useEffect } from "react";
import Course from "../components/Course/Course";
import { useParams } from "react-router-dom";
import { useHttp } from "../hooks/useHttp";

function CoursePage() {
  const params = useParams();
  const { key } = params;
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: dataCourses,
    error: errorCourses,
    loading: loadingCourses,
    sendRequest: sendRequestCourses,
  } = useHttp();

  const {
    data: dataCoursesSearch,
    error: errorCoursesSearch,
    loading: loadingCoursesSearch,
    sendRequest: sendRequestCoursesSearch,
  } = useHttp();

  const getCourse = async () => {
    let response;
    if (key == "") {
      sendRequestCourses(`/courses`);
      //   response = await fetch(`${process.env.REACT_APP_API_URL}/courses`);
    } else {
      sendRequestCoursesSearch(`/courses/${key}/search`);
      //   response = await fetch(
      // `${process.env.REACT_APP_API_URL}/courses/${id}/search`
    }
    const data = await response.json();
    setCourses(data);
    setIsLoading(false);
    console.log("from-getCourseBySearchKey", data);
  };

  useEffect(() => {
    if (dataCourses) {
    }
  }, [sendRequestCourses, dataCourses]);

  useEffect(() => {
    if (dataCoursesSearch) {
    }
  }, [sendRequestCoursesSearch, dataCoursesSearch]);

  useEffect(() => {
    getCourse();
  }, [key]);

  return (
    <Fragment>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <div className="bootstrap container">
          <div className="bg-red-500 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl m-4">
            <div className="flex flex-col justify-center">Kurslar</div>
          </div>
          <div className="card-list">
            {courses.length == 0 ? (
              <div className="flex flex-row justify-center text-3xl">{`"${key}" anahtar kelimesi ile ilgili kurs bulunamadi.`}</div>
            ) : (
              courses.map((course) => (
                <Course
                  key={course.uid}
                  uid={course.uid}
                  name={course.name}
                  description={course.description}
                />
              ))
            )}
            {}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CoursePage;
