import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useHttp } from "../../hooks/useHttp";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const ChunkAdd = ({ setNewItemShow, sectionUniqId, chunk }) => {
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  const [orderNo, setOrderNo] = useState(null);

  const {
    data: dataChunkCreate,
    error: errorChunkCreate,
    loading: loadingChunkCreate,
    sendRequest: sendRequestChunkCreate,
  } = useHttp();

  const {
    data: dataChunkUpdate,
    error: errorChunkUpdate,
    loading: loadingChunkUpdate,
    sendRequest: sendRequestChunkUpdate,
  } = useHttp();
  const {
    data: dataChunkDelete,
    error: errorChunkDelete,
    loading: loadingChunkDelete,
    sendRequest: sendRequestChunkDelete,
  } = useHttp();

  const handleChunknName = (e) => {
    console.log(e.target.value);
    setName(e.target.value);
  };

  const handleChunkDesc = (e) => {
    console.log(e.target.value);
    setDesc(e.target.value);
  };

  const handleOrderNo = (e) => {
    console.log(e.target.value);
    setOrderNo(e.target.value);
  };

  //handle submit

  const handleSubmit = () => {
    const data = {
      name: name,
      description: desc,
      sectionUniqId: sectionUniqId,
      orderNo: orderNo,
    };
    sendRequestChunkCreate("/chunks", "POST", data);
    // console.log(data);

    // fetch(`${process.env.REACT_APP_API_URL}/chunks`, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${authCtx.token}`,
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => console.log(response.json()))
    //   .then((data) => {
    //     console.log("Success:", data);
    //     setNewItemShow(false);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
  };

  useEffect(() => {
    if (dataChunkCreate) {
      setNewItemShow(false);
    }
  }, [dataChunkCreate]);

  useEffect(() => {
    if (chunk !== null) {
      setName(chunk.name);
      setDesc(chunk.description);
      setOrderNo(chunk.orderNo);
    } else {
      console.log("girdi");
      setName("");
      setDesc("");
      setOrderNo("");
    }
  }, [chunk]);

  //
  const handleUpdate = () => {
    const data = {
      name: name,
      description: desc,
      sectionUniqId: sectionUniqId,
      orderNo: orderNo,
    };
    sendRequestChunkUpdate(`/chunks/${chunk.uniqId}`, "PUT", data);
    // console.log(data);

    // fetch(`${process.env.REACT_APP_API_URL}/chunks/${chunk.id}`, {
    //   method: "PUT",
    //   mode: "cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${authCtx.token}`,
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => console.log(response.json()))
    //   .then((data) => {
    //     console.log("Success:", data);
    //     setNewItemShow(false);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
  };

  useEffect(() => {
    if (dataChunkUpdate) {
      setNewItemShow(false);
    }
  }, [dataChunkUpdate]);

  const handleDelete = () => {
    sendRequestChunkDelete(`/chunks/${chunk.uniqId}`, "DELETE");
    setNewItemShow(false);
    // fetch(`${process.env.REACT_APP_API_URL}/chunks/${chunk.id}`, {
    //   method: "DELETE",
    //   mode: "cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${authCtx.token}`,
    //   },
    // })
    //   .then((response) => {
    //     console.log("Success:", response);
    //     setNewItemShow(false);
    //   })
    //   .catch((error) => {
    //     console.error("ErrorBurdan:", error);
    //   });
  };

  return (
    <>
      <div className="flex flex-row justify-center">
        {loadingChunkCreate || loadingChunkDelete || loadingChunkUpdate ? (
          <LocalSpinnerBar />
        ) : (
          <div className="flex flex-col justify-center gap-4 p-4">
            <div className="flex flex-row gap-4">
              <input
                placeholder="Lutfen kurs adini giriniz"
                value={name}
                onChange={handleChunknName}
                className="p-2 w-72 border border-black"
              />
            </div>

            <div className="flex flex-row gap-4">
              <textarea
                placeholder="Lutfen kurs aciklamasi giriniz"
                onChange={handleChunkDesc}
                value={desc}
                rows={5}
                className="p-2 h-32 w-72 border border-black"
              />
            </div>
            <div className="flex flex-row gap-4">
              <input
                type="number"
                placeholder="Lutfen unite sira numarasini giriniz"
                value={orderNo}
                onChange={handleOrderNo}
                className="p-2 w-72 border border-black"
              />
            </div>

            <div className="w-22 flex flex-row justify-center">
              {chunk !== null ? (
                <button
                  className="bg-green-200 p-2 w-32 rounded-md"
                  onClick={handleUpdate}
                >
                  Güncelle
                </button>
              ) : (
                <button
                  className="bg-green-200 p-2 w-32 rounded-md"
                  onClick={handleSubmit}
                >
                  Kayit Et
                </button>
              )}
              <button
                className="bg-red-200 p-2 m-2 w-32 rounded-md"
                onClick={handleDelete}
              >
                DELETE
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChunkAdd;
