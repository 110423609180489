import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";
import LocalLoadingSpinner from "../../components/UI/LocalSpinnerBar";

const MentorMenties = ({ courseUniqId }) => {
  const authCtx = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [menties, setMenties] = useState([]);

  const {
    data: dataMenties,
    error: errorMenties,
    loading: loadingMenties,
    sendRequest: sendRequestGetMenties,
  } = useHttp();
  const {
    data: dataAddMentie,
    error: errorAddMentie,
    loading: loadingAddMentie,
    sendRequest: sendRequestAddMentie,
  } = useHttp();

  const {
    data: dataDeleteMentie,
    error: errorDeleteMentie,
    loading: loadingDeleteMentie,
    sendRequest: sendRequestDeleteMentie,
  } = useHttp();

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  useEffect(() => {
    getEmails();
  }, []);

  const getEmails = async () => {
    await sendRequestGetMenties(`/userCourseMenties/menties/${courseUniqId}`);
  };

  useEffect(() => {
    if (dataMenties) {

      setMenties(dataMenties);
    }
  }, [dataMenties]);

  const deleteMentieHandler = (uniqId) => {
    if (uniqId != null && uniqId != "") {
      sendRequestDeleteMentie(`/userCourseMenties/${uniqId}`, "DELETE");
    }
  };

  useEffect(() => {
    if (dataDeleteMentie) {
      setMenties((prevItems) =>
        prevItems.filter((item) => item.uniqId !== dataDeleteMentie.uniqId)
      );
    }
  }, [dataDeleteMentie]);

  const handleEmailSubmit = async (index) => {
    if (email != null && email != "") {
      sendRequestAddMentie("/userCourseMenties", "POST", {
        email: email,
        courseUniqId: courseUniqId,
      });
    }
  };

  //Add Mentie
  useEffect(() => {
    if (dataAddMentie) {
      setMenties([...menties, dataAddMentie]);
      setEmail("");
    }
  }, [dataAddMentie]);

  return (
    <div className="flex flex-row max-md:flex-col max-md:items-center max-md:justify-center justify-around place-items-baseline min-h-screen bg-gray-100 px-4 py-6 md:gap-6">
      <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 max-h-96 max-w-lg bg-white rounded-lg shadow-md p-6 mb-6 md:mb-0">
        {loadingAddMentie ? (
          <LocalLoadingSpinner />
        ) : (
          <div>
            <h2 className="text-xl font-bold text-gray-700 text-center mb-6">
              Mentörlük yapılacak öğrencinin e-maili
            </h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <input
                  type="email"
                  placeholder={email}
                  value={email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                  className="flex-grow min-w-0 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                <button
                  onClick={() => handleEmailSubmit()}
                  className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 whitespace-nowrap"
                >
                  Ekle
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 max-h-96 max-w-lg bg-white rounded-lg shadow-md p-6 mt-6 md:mt-0">
        <div className="flex items-center space-x-4">
          {loadingDeleteMentie ? (
            <LocalLoadingSpinner />
          ) : (
            <h2 className="text-xl font-bold text-gray-700 text-center mb-6 items-center">
              Yolladığınız Mail Adresleri
            </h2>
          )}
        </div>
        <div className="space-y-4 flex flex-col">
          {menties.length != 0 && !loadingMenties ? (
            menties.map((item) => (
              <div
                key={item.email}
                className="flex items-center justify-between"
              >
                <li className="flex-1">{item.email}</li>
                <button
                  className="ml-4 px-2 py-1 bg-red-600 text-white rounded hover:bg-red-700"
                  onClick={() => deleteMentieHandler(item.uniqId)}
                >
                  Sil
                </button>
              </div>
            ))
          ) : menties.length == 0 && !loadingMenties ? (
            <div>Kayıtlı email yok.</div>
          ) : (
            <LocalLoadingSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorMenties;
