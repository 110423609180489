import React, { useState, useEffect } from "react";
import ChunkItemAdd from "./ChunkItemAdd";
import ChunkItemTek from "./ChunkItemTek";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Modal from "../UI/Modal";
import { useHttp } from "../../hooks/useHttp";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const ChunkItems = ({ chunkUniqId }) => {
  const [newItemShow, setNewItemShow] = useState(false);
  const [chunkItems, setChunkItems] = useState([]);
  const [chunkItem, setChunkItem] = useState(null);

  const {
    data: dataChunks,
    error: errorChunks,
    loading: loadingChunks,
    sendRequest: sendRequestChunks,
  } = useHttp();

  useEffect(() => {
    getChunkChunkItems();
  }, [newItemShow]);

  const getChunkChunkItems = async () => {
    sendRequestChunks(`/chunkItems/${chunkUniqId}/chunk`);
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/chunkItems/${chunkUniqId}/chunk`
    // );
    // const data = await response.json();

    // setChunkItems(data);
    // console.log(`chunkItems with chunkID ${chunkUniqId}`);
    // console.log(data);
  };

  useEffect(() => {
    if (dataChunks) {
      setChunkItems(dataChunks);
      console.log("dataChunks")
      console.log(dataChunks)
    }
  }, [dataChunks]);

  useEffect(() => {
    if (chunkItem !== null) {
      setNewItemShow(true);
    }
  }, [chunkItem]);

  return (
    <>
      <div className="flex flex-row gap-2">
        <div className="border border-l-red-400"></div>
        <div className="flex">
          <div className=" flex flex-col">
            <div className="flex justify-center">
              <div className="text-3xl my-2 flex flex-row gap-4">
                Icerikler{" "}
                <span
                  className="flex flex-col justify-end"
                  onClick={() => {
                    setChunkItem(null);
                    setNewItemShow(true);
                  }}
                >
                  <PlusCircleIcon className="text-red-400 h-6 w-6" />
                </span>
              </div>
            </div>
           
            <div>
              {chunkItems.length > 0 && !loadingChunks  ? (
                chunkItems.map((item) => (
                  <ChunkItemTek item={item} setChunkItem={setChunkItem} />
                ))
              ) : chunkItems.length == 0 && !loadingChunks ? (
                <div className="m-4 p-4">
                  Lutfen artiya basarak icerik ekleyiniz.
                </div>
              ): (<LocalSpinnerBar/>)}
            </div>
          </div>
          <div className="flex flex-col">
            {newItemShow && (
              <Modal
                onClose={() => {
                  setNewItemShow(false);
                  setChunkItem(null);
                }}
                title={
                  chunkItem != null
                    ? `${chunkItem.orderNo}- İçerik Güncelle`
                    : `İçerik Ekleme`
                }
              >
                <ChunkItemAdd
                  setNewItemShow={setNewItemShow}
                  chunkUniqId={chunkUniqId}
                  chunkItem={chunkItem}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChunkItems;
