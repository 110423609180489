import React, { useEffect, useState, useContext } from "react";
import Profile from "../components/Profile/Profile";
import AuthContext from "../store/auth-context";
import { useHttp } from "../hooks/useHttp";
import LoadingSpinner from "../components/UI/LoadingSpinner";

const ProfilePage = () => {
	const authCtx = useContext(AuthContext);
	const { data: dataUser, error: errorUser, loading: loadingUser, sendRequest: sendRequestUser } = useHttp();
	const [user, setUser] = useState(null);

	useEffect(() => {
		getUser();
	}, []);

	const getUser = async () => {
		sendRequestUser("/users/single");
	};

	useEffect(() => {
		if (dataUser) {
			setUser(dataUser);
		}
	}, [sendRequestUser, dataUser]);

	return (
		<div className='page page-view'>
			{user == null && loadingUser ? (
				<LoadingSpinner />
			) : user == null && !loadingUser ? (
				<h1>User bulunamadı</h1>
			) : (
				<Profile user={user} />
			)}
		</div>
	);
};

export default ProfilePage;
