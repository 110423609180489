import React, { useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";

const ChunkItemTek = ({ item, setChunkItem }) => {
	// const [showChunkItems, setShowChunkItems] = useState(false);

	const { name, description, orderNo, type } = item;

	return (
		<>
			<div className='m-4 border border-gray-300 bg-blue-100 rounded-lg flex flex-row p-2' onClick={() => {}}>
				<div className='m-2 flex flex-col'>
					<span
						onClick={() => {
							setChunkItem(item);
						}}
						className='cursor-pointer bg-white  '
					>
						<PencilSquareIcon className='text-red-300 w-6 h-6' />
					</span>
				</div>

				<div className='w-72'>
					<div className='flex flex-row justify-end'>Sira: {orderNo}</div>
					<span className='flex flex-row justify-center'>{name} </span>
					{/* <div>Descrition {description}</div> */}
					<div className='flex flex-row justify-center'>{type}</div>
					{/* <div>sectionId: {chunkId}</div> */}
				</div>
			</div>
		</>
	);
};

export default ChunkItemTek;
