import React, { useState, useContext, useMemo, useEffect } from "react";
import SortableTable from "../UI/SortableTable/SortableTable";
import { useHttp } from "../../hooks/useHttp";
import Modal from "../UI/Modal";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import LocalSpinnerBar from "../UI/LocalSpinnerBar";

const Teacher = () => {
  const [users, setUsers] = useState([]);
  const [opneEditModal, setOpenEditModal] = useState(false);
  const [openAddTeacherModal, setOpenAddTeacherModal] = useState(false);
  const [formData, setFormData] = useState({
    uniqId: "",
    name: "",
    surname: "",
    email: "",
    birthday: "",
    homeTown: "",
    occupation: "",
    emailConfirmed: "",
    phoneConfirmed: "",
    phone: "",
    password: "",
    role: "Teacher",
    active: false,
    managerActive: false,
  });
  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const {
    data: dataTeachers,
    error: errorTeachers,
    loading: loadingTeachers,
    sendRequest: sendRequestTeachers,
  } = useHttp();
  const {
    data: dataAddTeacher,
    error: errorAddTeacher,
    loading: loadingAddTeacher,
    sendRequest: sendRequestAddTeacher,
  } = useHttp();
  const {
    data: dataUpdateTeacher,
    error: errorUpdateTeacher,
    loading: loadingUpdateTeacher,
    sendRequest: sendRequestUpdateTeacher,
  } = useHttp();

  const columns = useMemo(
    () => [
      {
        Header: "Ad",
        accessor: "name",
        filter: "fuzzyText",
        disableFilters: true,
      },
      {
        Header: "Soyad",
        accessor: "surname",
        disableFilters: true,
      },
      {
        Header: "Doğum günü",
        accessor: "birthday",
        disableFilters: true,
      },
      {
        Header: "Memleket",
        accessor: "homeTown",
        disableFilters: true,
      },
      {
        Header: "Meslek",
        accessor: "occupation",
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.emailConfirmed != "undefined"
                  ? row.row.original.emailConfirmed
                  : false
              }
            />
            <div>{row.row.original.email}</div>
          </div>
        ),
      },
      {
        Header: "Telefon ",
        accessor: "phone",
        disableFilters: true,
        Cell: (row) =>
          row.row.original.phone ? (
            <div className="flex flex-row gap-3 text-center">
              <input
                type="checkbox"
                checked={
                  typeof row.row.original.phoneConfirmed != "undefined"
                    ? row.row.original.phoneConfirmed
                    : false
                }
              />
              <div>{row.row.original.phone}</div>
            </div>
          ) : (
            <div>Veri yok</div>
          ),
      },
      {
        Header: "Active",
        accessor: "active",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.active != "undefined"
                  ? row.row.original.active
                  : false
              }
            />
          </div>
        ),
      },
      {
        Header: "Admin Onay",
        accessor: "managerConfirmed",
        disableFilters: true,
        Cell: (row) => (
          <div className="flex flex-row gap-3 text-center">
            <input
              type="checkbox"
              checked={
                typeof row.row.original.managerConfirmed != "undefined"
                  ? row.row.original.managerConfirmed
                  : false
              }
            />
          </div>
        ),
      },
      {
        Header: "Edit",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => (
          <div>
            <button
              type="button"
              className="button-warn"
              onClick={(e) => onCustomerEdit(row.row.original)}
            >
              Edit
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const onCustomerEdit = (e) => {
    setFormData({
      uniqId: e.uniqId,
      name: e.name,
      surname: e.surname,
      email: e.email,
      password: e.password,
      birthday: e.birthday,
      homeTown: e.birthday,
      occupation: e.occupation,
      phone: e.phone,
      emailConfirmed: e.emailConfirmed,
      phoneConfirmed: e.phoneConfirmed,
      role: "Teacher",
      active: e.active,
      managerConfirmed: e.managerConfirmed,
    });
    setOpenEditModal(true);
    document.body.classList.add("overflow-hidden");
  };

  const handleSubmitOgretmen = async (e) => {
    e.preventDefault();
    await sendRequestAddTeacher("/admin/users", "POST", formData);
  };
  useEffect(() => {
    if (dataAddTeacher) {
      setUsers([...users, dataAddTeacher]);
      handleCloseAddModal();
    }
  }, [dataAddTeacher]);

  const handleUpdateOgretmen = async (e) => {
    e.preventDefault();
    await sendRequestUpdateTeacher("/admin/users", "PUT", formData);
  };

  useEffect(() => {
    if (dataUpdateTeacher) {
      console.log("dataUpdateTeacher");
      console.log(dataUpdateTeacher);
      setUsers((prevItems) =>
        prevItems.map((item) =>
          item.uniqId === dataUpdateTeacher.uniqId ? dataUpdateTeacher : item
        )
      );
      handleCloseEditModal();
    }
  }, [dataUpdateTeacher]);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleCloseAddModal = () => {
    setOpenAddTeacherModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  useEffect(() => {
    getTeachers();
  }, []);

  const getTeachers = async () => {
    await sendRequestTeachers("/roles/Teacher/roleName");
  };

  useEffect(() => {
    if (dataTeachers) {
      setUsers(dataTeachers);
    }
  }, [sendRequestTeachers, dataTeachers]);

  return (
    <div className="h-auto">
      {opneEditModal && (
        <Modal
          onClose={handleCloseEditModal}
          title="Öğretmen bilgisi düzenleme"
        >
          <div className="">
            <form onSubmit={handleUpdateOgretmen} className="space-y-4 mb-10">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adınız
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Soyadınız
                </label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  value={formData.surname}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Adresiniz
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  disabled
                  value={formData.email}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="emailConfirmed" className="flex items-center">
                  <input
                    type="checkbox"
                    id="emailConfirmed"
                    name="emailConfirmed"
                    checked={formData.emailConfirmed}
                    onChange={handleChange}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-sm font-medium text-gray-600">
                    Email Onayı
                  </span>
                </label>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-gray-600"
                >
                  Birthday:
                </label>
                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  value={formData.birthday}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="homeTown"
                  className="block text-sm font-medium text-gray-600"
                >
                  Memleket:
                </label>
                <input
                  type="text"
                  id="homeTown"
                  name="homeTown"
                  value={formData.homeTown}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="occupation"
                  className="block text-sm font-medium text-gray-600"
                >
                  Meslek:
                </label>
                <input
                  type="text"
                  id="occupation"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-600"
                >
                  Phone:
                </label>
                <input
                  placeholder="Başında 0 olmadan 5382892872 gibi yazınınız."
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className=" flex gap-3">
                <div className="mb-4 border p-1">
                  <label htmlFor="phoneConfirmed" className="flex items-center">
                    <input
                      type="checkbox"
                      id="phoneConfirmed"
                      name="phoneConfirmed"
                      checked={formData.phoneConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Telefon Onayı
                    </span>
                  </label>
                </div>
                <div className="mb-4 border p-1">
                  <label htmlFor="active" className="flex items-center">
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      checked={formData.active}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Kullanıcı Aktif
                    </span>
                  </label>
                </div>
                <div className="mb-4 border p-1">
                  <label
                    htmlFor="managerConfirmed"
                    className="flex items-center"
                  >
                    <input
                      type="checkbox"
                      id="managerConfirmed"
                      name="managerConfirmed"
                      checked={formData.managerConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Admin Onayı
                    </span>
                  </label>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-3/4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Düzenle
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {openAddTeacherModal && (
        <Modal onClose={handleCloseAddModal} title="Öğretmen ekleme">
          <div className="">
            <form onSubmit={handleSubmitOgretmen} className="space-y-4 mb-10">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adınız
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Soyadınız
                </label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  value={formData.surname}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-gray-700"
                >
                  Email Adresiniz
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="emailConfirmed" className="flex items-center">
                  <input
                    type="checkbox"
                    id="emailConfirmed"
                    name="emailConfirmed"
                    checked={formData.emailConfirmed}
                    onChange={handleChange}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-sm font-medium text-gray-600">
                    Email Onayı
                  </span>
                </label>
              </div>
              {/* <div>
								<label htmlFor='email' className='text-sm font-medium text-gray-700'>
									Email Adresiniz
								</label>
								<Dropdownlist
									content={teachers.map((item) => (
										<option value={item.uniqId}>
											{item.name} - {item.email}
										</option>
									))}
									title='Rol Seçiniz.'
									handleSelected={handleSelectTeacher}
									selectedItem={teacherId}
								/>
							</div> */}

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  value={formData.password}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-gray-600"
                >
                  Birthday:
                </label>
                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  value={formData.birthday}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="homeTown"
                  className="block text-sm font-medium text-gray-600"
                >
                  Memleket:
                </label>
                <input
                  type="text"
                  id="homeTown"
                  name="homeTown"
                  value={formData.homeTown}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="occupation"
                  className="block text-sm font-medium text-gray-600"
                >
                  Meslek:
                </label>
                <input
                  type="text"
                  id="occupation"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-600"
                >
                  Phone:
                </label>
                <input
                  placeholder="Başında 0 olmadan 5382892872 gibi yazınınız."
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className=" flex gap-3">
                <div className="mb-4 border p-1">
                  <label htmlFor="phoneConfirmed" className="flex items-center">
                    <input
                      type="checkbox"
                      id="phoneConfirmed"
                      name="phoneConfirmed"
                      checked={formData.phoneConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Telefon Onayı
                    </span>
                  </label>
                </div>
                <div className="mb-4 border p-1">
                  <label htmlFor="active" className="flex items-center">
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      checked={formData.active}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Kullanıcı Aktif
                    </span>
                  </label>
                </div>
                <div className="mb-4 border p-1">
                  <label
                    htmlFor="managerConfirmed"
                    className="flex items-center"
                  >
                    <input
                      type="checkbox"
                      id="managerConfirmed"
                      name="managerConfirmed"
                      checked={formData.managerConfirmed}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm font-medium text-gray-600">
                      Admin Onayı
                    </span>
                  </label>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-3/4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Ekle
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      <>
        <div className="flex flex-row justify-between">
          <div className="font-semibold text-2xl m-2 w-auto">
            Öğretmenler Tablosu
          </div>
          <div
            className="button-green w-30 flex justify-between"
            onClick={() => setOpenAddTeacherModal(true)}
          >
            <span className="">Öğretmen</span>
            <PlusCircleIcon />
          </div>
        </div>
        {users.length != 0 && !loadingTeachers ? (
          <div>
            <SortableTable columns={columns} data={users} search={false} />
          </div>
        ) : users.length == 0 && !loadingTeachers ? (
          <div>Öğretmenler bulunamadı.</div>
        ) : (
          <div>
            <LocalSpinnerBar />
          </div>
        )}
      </>
    </div>
  );
};

export default Teacher;
