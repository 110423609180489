import React, { useState, useEffect } from "react";
import Course from "../components/Course/Course";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import LocalSpinnerBar from "../components/UI/LocalSpinnerBar";
import { useHttp } from "../hooks/useHttp";

function CoursePage() {
  const [courses, setCourses] = useState([]);

  const { data, error, loading, sendRequest } = useHttp();
  useEffect(() => {
    sendRequest("/courses");
  }, []);
  useEffect(() => {
    if (data) {
      setCourses(data);
    }
  }, [data]);

  return (
    <>
      <div className="bg-red-300 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl my-2">
        <div className="flex flex-col justify-center">Kurslar</div>
      </div>
      <div className="">
        <div className="flex justify-center">
          {courses.length !== 0 && loading == false ? (
            courses.map((course) => (
              <Course
                key={course.uniqId}
                id={course.uniqId}
                name={course.name}
                description={course.description}
                uid={course.uniqId}
                course={course}
              />
            ))
          ) : loading == false && courses.length == 0 ? (
            <div>Kurs yok</div>
          ) : (
            <div>
              <LocalSpinnerBar />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CoursePage;
